import Vue from "vue";
import App from "./App.vue";
import i18n from './js/plugins/i18n'
import './js/plugins/color';
import router from "./js/router";
import store from "./js/store";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "./styles/index.scss";
import './js/plugins/gtm';
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  extend
} from "vee-validate/dist/vee-validate.full";
import FormItemWrapper from "@/validation/FormItemWrapper.vue";
import customValidation from "@/validation/customValidation.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSquare,
  faCircle,
  faExclamationTriangle,
  faPlay,
  faShoppingCart,
  faCheck,
  faRuler
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import VueKonva from "vue-konva";
import axios from "axios";
import VueAxios from "vue-axios";
import VueShepherd from 'vue-shepherd';
import * as Resize from "./js/helper/iframeResizer.contentWindow.js";

library.add(
  faSquare,
  faCircle,
  faExclamationTriangle,
  faPlay,
  faShoppingCart,
  faCheck,
  faRuler
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

Vue.use(VueAxios, axios);
Vue.use(VueShepherd);
Vue.use(ElementUI, { locale });
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
Vue.component("form-item-wrapper", FormItemWrapper);
configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  }
});
customValidation(extend);
Vue.use(VueKonva);
Vue.use(Resize);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
