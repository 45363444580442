<template>
  <validation-observer
    ref="buttonObserver"
    v-slot="{ valid }"
  >
    <h4>{{ $t('gasketSideTitle') }}:</h4>
    <el-radio-group
      v-model="selectedSideGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in siteGaskets"
        :key="gasket.id"
        :label="getLimitedButton(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          type="text"
          class="info-box"
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <img
          :src="gasket.image?.src"
          class="image fit"
        >
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
      <el-radio-button
        v-if="selectedHinge && !selectedHinge.gasket_required"
        :label="{ title: 'Keine Dichtung' }"
        class="card"
        rules="required"
      >
        <el-button
          type="text"
          class="info-box"
          @click="openInfoBox($t('withoutDescTitle'), $t('withoutDescHtml'))"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <img
          :src="getImgUrl('ohne')"
          class="image fit"
        >
        <div class="caption">
          <span class="title">{{ $t('withoutGasket') }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <h4 v-if="midGaskets.length > 0">
      {{ $t('gasketMidTitle') }}:
    </h4>
    <el-radio-group
      v-if="midGaskets.length > 0"
      v-model="selectedMidGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in midGaskets"
        :key="gasket.id"
        :label="getLimitedButton(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          type="text"
          class="info-box"
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <img
          :src="gasket.image?.src"
          class="image fit"
        >
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <h4>{{ $t('gasketBottomTitle') }}:</h4>
    <el-radio-group
      v-model="selectedBottomGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in bottomGaskets"
        :key="gasket.id"
        :label="getLimitedButton(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          type="text"
          class="info-box"
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <img
          :src="gasket.image?.src"
          class="image fit"
        >
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants, 0) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>

      <el-radio-button
        :label="{ title: 'Keine Dichtung' }"
        class="card"
        rules="required"
      >
        <el-button
          type="text"
          class="info-box"
          @click="openInfoBox($t('withoutDescTitle'), $t('withoutDescHtml'))"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <img
          :src="getImgUrl('ohne')"
          class="image fit"
        >
        <div class="caption">
          <span class="title">{{ $t('withoutGasket') }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <div
      align="right"
    >
      <el-button
        type="primary"
        :disabled="!valid || !selectedBottomGasket || !selectedSideGasket"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Dichtung",
  props: {
    nextIndex: {
      type: Number,
      default: 4
    },
  },
  computed: {
    ...mapFields([
      "length",
      "height",
      "selectedHinge",
      "selectedBottomGasket",
      "selectedMidGasket",
      "selectedSideGasket",
    ]),
    bottomGaskets() {
      return this.$store.getters.filterAccessoriesBy([
        ['title', 'Wasserabweisprofil'],
        ['type', 'Dichtung']
      ]);
    },
    midGaskets() {
      return this.$store.getters.filterAccessoriesBy([
        ['title', 'Magnetdichtung 180°'],
        ['type', 'Dichtung']
      ]);
    },
    siteGaskets() {
      return this.$store.getters.filterAccessoriesBy([
        ['title', 'Streifdichtung'],
        ['type', 'Dichtung']
      ]);
    }
  },
  watch: {
    midGaskets() {
      if (this.midGaskets.length && !this.selectedMidGasket) {
        this.selectedMidGasket = this.getLimitedButton(this.midGaskets[1]);
      }
    }
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    getImgUrl(filename) {
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + filename + ".jpg");
    },
    getLimitedButton(gasket) {
      const copy = Object.assign({}, gasket);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      return copy;
    },
    getVariantPrice(variants, pos = -1) {
      const amount = pos > -1 ? pos : variants.length - 1;
      return parseFloat(variants[amount].price).toFixed(2);
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `gasket_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('gasket_', '');
      });

      return segments.join(' ');
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>