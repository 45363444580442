var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-line',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    points: [
      0, 0,
      0, _vm.thickness,
      _vm.length, _vm.thickness,
      _vm.length, 0,
      (_vm.length - _vm.dimensision), -_vm.dimensision,
      _vm.thickness + _vm.dimensision, -_vm.dimensision,
    ],
    closed: true,
    fill: _vm.fill,
    shadowColor: 'rgba(0, 0, 0, .4)',
    shadowBlur: 15,
    shadowOffset: { x: 0, y: 5 },
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }