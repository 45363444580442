<template>
  <el-form v-loading="loading">
    <el-collapse
      v-model="activeStep"
      accordion
    >
      <validation-observer
        ref="formatObserver"
        v-slot="{ invalid }"
      >
        <el-collapse-item :name="1">
          <template slot="title">
            <h3>{{ $t('glassSpec') }}</h3>
            <font-awesome-icon
              v-if="activeStep > 1"
              class="status-icon"
              icon="check"
            />
          </template>
          <ScheibenSpezifizierung 
            @validate="validateGlassWidth()"
          />
          <div align="right">
            <el-button
              type="primary"
              :disabled="invalid || isCurrentConfigurationTooNarrow"
              @click="activeStep++"
            >
              {{ $t('nextBtn') }}
            </el-button>
          </div>
        </el-collapse-item>
      </validation-observer>
      <el-collapse-item
        :name="2"
        :disabled="activeStep < 2"
      >
        <template slot="title">
          <h3>{{ $t('edges') }}</h3>
          <font-awesome-icon
            v-if="activeStep > 2"
            class="status-icon"
            icon="check"
          />
        </template>
        <Kanten :show-next-btn="false" />
        <h3>{{ $t('edgesTreatmentQ') }}</h3>
        <el-switch
          v-model="onlyFrontEdge"
          :active-text="$t('edgesTreatmentActiveText')"
          :inactive-text="$t('edgesTreatmentInactiveText')"
          active-color="#1bafd6"
          inactive-color="black"
          :disabled="selectedEdge.includes('entgratet')"
        />
        <div align="right">
          <el-button
            type="primary"
            @click="activeStep++"
          >
            {{ $t('nextBtn') }}
          </el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :name="3"
        :disabled="activeStep < 3"
      >
        <template slot="title">
          <h3>{{ $t('extras') }}</h3>
          <font-awesome-icon
            v-if="activeStep > 3"
            class="status-icon"
            icon="check"
          />
        </template>
        <TerassenExtras />
        <div align="right">
          <el-button
            type="primary"
            @click="activeStep++"
          >
            {{ $t('nextBtn') }}
          </el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :name="4"
        :disabled="activeStep < 4"
      >
        <template slot="title">
          <h3>{{ $t('overview') }}</h3>
          <font-awesome-icon
            v-if="activeStep > 4"
            class="status-icon"
            icon="check"
          />
        </template>
        <TerassenÜbersicht />
        <div align="right">
          <el-button
            type="primary"
            @click="checkout"
          >
            {{ $t('chkoutDialogBtn') }}
          </el-button>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import ScheibenSpezifizierung from "@/components/Terassendach/ScheibenSpezifizierung.vue";
import TerassenExtras from "@/components/Terassendach/TerassenExtras.vue";
import TerassenÜbersicht from "@/components/Terassendach/TerassenÜbersicht.vue";
import Kanten from "@/components/Layout/Kanten.vue";

export default {
  name: "TerassenDachKonfigurator",
  components: {
    ScheibenSpezifizierung, Kanten, TerassenExtras, TerassenÜbersicht
  },
  props: {
    whatIf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorDialog: false,
      blockSteps: false,
      loading: false
    };
  },
  computed: {
    ...mapFields([
      "activeStep",
      "maxStep",
      "selectedFormat",
      "selectedEdge",
      "onlyFrontEdge",
    ]),
    isCurrentConfigurationTooNarrow() {
      return this.$store.getters.isCurrentConfigurationTooNarrow;
    }
  },
  watch: {
    activeStep() {
      this.$emit("datachange");
    }
  },
  created() {
    this.getAdditionalTerraceProducts();
  },
  methods: {
    checkout() {
      this.loading = true;
      const items = this.$store.getters.getShopifyTerraceGlassLineItems;

      if (this.whatIf) {
        this.$emit("postLineItems", null, items);
        this.showDialog = false;
        this.loading = false;
      } else {
        this.$http
          .post(
            process.env.VUE_APP_CHECKOUT,
            this.$store.getters.getShopifyTerraceGlassProduct
          )
          .then(response => {
            this.$emit("postLineItems", response.data, items);
          })
          .catch(() => {
            this.$emit('notify', {
              title: this.$t('cartError'),
              message: this.$t('addToCartError'),
              type: 'error'
            });
          })
          .finally(() => {
            this.showDialog = false;
            this.loading = false;
          });
      }
    },
    getAdditionalTerraceProducts() {
      this.errorDialog = false;
      let sku = "TER-ZUB:*";
      this.$http
        .get(`${process.env.VUE_APP_GET_PRODUCTS_BY_SKU}${sku}`)
        .then(response => {
          for (let item of response.data) {
            this.$store.commit("addAdditionalTerraceProduct", item);
          }
          this.$emit("loadSavedConfig");
        })
        .catch(() => {
          this.errorDialog = true;
        });
    },
    validateGlassWidth() {
      this.$nextTick(() => {
        this.$refs.formatObserver.refs.widthValidator.validate();
      })
    }
  }
};
</script>
