<template>
  <validation-observer
    ref="formatObserver"
    v-slot="{ invalid }"
  >
    <el-row style="margin-bottom: 8px;">
      <el-radio-group
        v-model="selectedFormat"
        fill="#1bafd6"
      >
        <el-radio-button
          v-for="format of possibleFormats"
          :key="format.title"
          :label="format.title"
        >
          <img
            :src="getImgUrl(format)"
            style="width: 24px"
          >
        </el-radio-button>
      </el-radio-group>
    </el-row>
    <el-row
      style="margin-bottom: 8px"
      :gutter="12"
    >
      <el-col
        :xs="12"
        :sm="5"
        :md="5"
        :lg="4"
        :xl="3"
      >
        <form-item-wrapper
          :label="$t('width')"
          :required="true"
          :rules="{
            required: true,
            numeric: true,
            between: { min: minWidth, max: maxWidth }
          }"
        >
          <el-input
            v-model="length"
          >
            <span slot="suffix">mm</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
      <el-col
        :xs="12"
        :sm="5"
        :md="5"
        :lg="4"
        :xl="3"
      >
        <form-item-wrapper
          :label="$t('height')"
          :required="true"
          :rules="{
            required: true,
            numeric: true,
            between: { min: minHeight, max: maxHeight }
          }"
        >
          <el-input
            v-model="height"
          >
            <span slot="suffix">mm</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
      <el-col
        :xs="8"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="2"
      >
        <form-item-wrapper
          :label="$t('weight')"
          :rules="{ required: true }"
        >
          <el-input
            v-model="weight"
            :disabled="true"
          >
            <span slot="suffix">kg</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
    </el-row>
    <v-stage
      v-if="!isNaN(length) && !isNaN(height) && !isNaN(ratio)"
      ref="stage"
      :config="stageSize"
    >
      <!--
        S T A R T
        R E C T A N G L E
        -------------------------------------------------------------------------
      -->
      <v-layer>
        <v-rect
          :config="{
            x: 20,
            y: 20,
            width: length * ratio,
            height: height * ratio,
            fillLinearGradientStartPoint: { x: (height * ratio) / 2, y: 0 },
            fillLinearGradientEndPoint: { x: 20, y: (length * ratio) / 2 },
            fillLinearGradientColorStops: [0, '#000', 1, '#222'],
            stroke: '#000',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
        <v-rect
          :config="{
            x: 20 + frameThickness / 2 * ratio,
            y: 20 + frameThickness / 2 * ratio,
            width: (length - frameThickness) * ratio,
            height: (height - frameThickness) * ratio,
            fillLinearGradientStartPoint: { x: ((height - frameThickness) * ratio) / 2, y: 0 },
            fillLinearGradientEndPoint: { x: 20, y: ((length - frameThickness) * ratio) / 2 },
            fillLinearGradientColorStops: [0, '#fcfcfc', 1, '#ddd'],
            stroke: '#000',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
      </v-layer>
      <!--
        -------------------------------------------------------------------------
        R E C T A N G L E
        E N D
      -->
      <v-layer>
        <v-line
          :config="{
            x: 5,
            y: 20,
            points: [10, 0, 0, 0, 0, height * ratio, 10, height * ratio],
            stroke: 'black',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
        <v-line
          :config="{
            x: 20,
            y: height * ratio + 20 + 15,
            points: [length * ratio, -10, length * ratio, 0, 0, 0, 0, -10],
            stroke: 'black',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
      </v-layer>
      <v-layer>
        <v-label
          :config="{
            x: 1,
            y: 20 + (height / 2) * ratio + 15
          }"
        >
          <v-tag :config="{ fill: '#fff', rotation: 270 }" />
          <v-text
            :config="{
              text: height,
              fontSize: 10,
              align: 'center',
              verticalAlign: 'middle',
              rotation: 270,
              width: 30
            }"
          />
        </v-label>
        <v-label
          :config="{
            x: 20 + (length * ratio) / 2 - 15,
            y: height * ratio + 20 + 10
          }"
        >
          <v-tag :config="{ fill: '#fff' }" />
          <v-text
            :config="{
              text: length,
              fontSize: 10,
              align: 'center',
              verticalAlign: 'middle',
              width: 30
            }"
          />
        </v-label>
      </v-layer>
    </v-stage>
    <GlassThickness style="margin-top: 12px" />
    <div align="right">
      <el-button
        type="primary"
        :disabled="invalid"
        @click="$emit('nextStep', 1)"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import GlassThickness from "@/components/Glasart/Glasstaerke.vue";
import i18n from "@/js/plugins/i18n";

const frame_width = window.innerWidth - 40;
const frame_height = 400;

export default {
  name: "FrameFormat",
  components: {
    GlassThickness
  },
  data() {
    return {
      possibleFormats: [{ title: i18n.t('rectangle'), image: "frame" }],
      stageSize: {
        width: frame_width,
        height: frame_height
      },
      ratio: 1,
      weight: 0,
    };
  },
  computed: {
    ...mapFields([
      "glassKind",
      "selectedFormat",
      "frameThickness",
      "length",
      "height",
      "minWidth",
      "maxWidth",
      "minHeight",
      "maxHeight",
      "price"
    ])
  },
  watch: {
    height(){
      this.updateCanvas();
    },
    length() {
      this.updateCanvas();
    }
  },
  created() {
    this.length1 = 200;
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    calculateWeight() {
      this.weight = this.$store.getters.calculateWeight.toFixed(1);
    },
    getImgUrl(format) {
      var file = format.image + "_konva";
      var images = require.context("../../assets/", false, /\.svg$/);
      if (this.selectedFormat === format.title) {
        file += "_selected";
      }
      return images("./" + file + ".svg");
    },
    updateCanvas() {
      this.calculateWeight();
      this.stageSize.height = frame_height;
      var frameWidth = parseInt(this.length);
      var frameHeight = parseInt(this.height);

      var wr = (this.stageSize.width - 45) / frameWidth || 0;
      var hr = (this.stageSize.height - 45) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = ratio;
      this.stageSize.height = this.height * ratio + 55;
    },
    resizeCanvas() {
      this.stageSize.width = window.innerWidth - 40;
      this.updateCanvas();
    }
  }
};
</script>
