<template>
  <div>
    <v-line
      :config="{
        x: x,
        y: y,
        points: [
          dimensision, Math.abs(dimensision),
          dimensision, height - Math.abs(dimensision),
          dimensision * 2, height - Math.abs(dimensision),
        ],
        closed: true,
        fill: 'black',
        opacity: 0.1
      }"
    />

    <v-line
      :config="{
        x: x,
        y: y,
        points: [
          dimensision, height - Math.abs(dimensision),
          dimensision * 2, height - Math.abs(dimensision),
          0, height,
        ],
        closed: true,
        fillLinearGradientStartPoint: { x: dimensision, y: height - Math.abs(dimensision) },
        fillLinearGradientEndPoint: { x: 0, y: height },
        fillLinearGradientColorStops: [0, 'black', 1, 'transparent'],
        opacity: 0.1
      }"
    />
  </div>
</template>
<script>
export default {
  name: "Shadow",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    dimensision: {
      type: Number,
      default: 0
    }
  }
}
</script>