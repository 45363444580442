var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        _vm.dimensision, Math.abs(_vm.dimensision),
        _vm.dimensision, _vm.height - Math.abs(_vm.dimensision),
        _vm.dimensision * 2, _vm.height - Math.abs(_vm.dimensision),
      ],
      closed: true,
      fill: 'black',
      opacity: 0.1
    }}}),_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        _vm.dimensision, _vm.height - Math.abs(_vm.dimensision),
        _vm.dimensision * 2, _vm.height - Math.abs(_vm.dimensision),
        0, _vm.height,
      ],
      closed: true,
      fillLinearGradientStartPoint: { x: _vm.dimensision, y: _vm.height - Math.abs(_vm.dimensision) },
      fillLinearGradientEndPoint: { x: 0, y: _vm.height },
      fillLinearGradientColorStops: [0, 'black', 1, 'transparent'],
      opacity: 0.1
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }