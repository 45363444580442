var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[_c('v-rect',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      width: _vm.length,
      height: _vm.height,
      fill: _vm.color,
      shadowColor: 'rgba(0, 0, 0, .1)',
      shadowBlur: 2,
      shadowOffset: { x: -2, y: 0 },
    }}}),_c('v-line',{attrs:{"config":{
      x: _vm.x + _vm.length / 2,
      y: _vm.y + 40 * _vm.ratio,
      points: [0, 0, 0, _vm.height - 80 * _vm.ratio],
      stroke: _vm.getRecessedGripColor(),
      strokeWidth: 25 * _vm.ratio,
      lineCap: 'round',
      lineJoin: 'round',
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }