<template>
  <div>
    <template
      v-if="
        handle && (handle.includes('isolierglas') || lotus) && !handle.includes('optishower') && !handle.includes('hust') && !handle.includes('simple') && frames.length == 0
      "
    >
      <h2>{{ $t('lotusLayer') }}</h2>
      {{ $t('lotusLayerText') }}
      <br>
      <el-switch
        v-model="lotusCoating"
        :active-text="$t('boolTrueText')"
        :inactive-text="$t('boolFalseText')"
        active-color="#1bafd6"
        inactive-color="black"
      />
    </template>
    <template v-if="expressProductionTime">
      <h2>{{ $t('expressProduction') }}</h2>
      <span v-if="!isBlocked() && !heatSoakTest">{{ $t('expressProductionText') }}<br></span>
      <el-switch
        v-if="!isBlocked() && !heatSoakTest"
        v-model="expressProduction"
        :active-text="$t('boolTrueText')"
        :inactive-text="$t('boolFalseText')"
        active-color="#1bafd6"
        inactive-color="black"
      />
      <el-alert
        v-if="isBlocked() || heatSoakTest"
        type="info"
        show-icon
        :closable="false"
      >
        <template>
          {{ heatSoakTest ? $t('heatSoakMsg') : $t('highDemandMsg') }}
          <!-- {{ $t('holidayMsg', {'date': getFormatDate() }) }} -->
        </template>
      </el-alert>
      <h4>
        {{ $t('timeOfProduktion') }} :
        {{ expressProduction ? expressProductionTime : productionTime }}
      </h4>
    </template>
    <template v-if="heatSoak">
      <h2>
        {{ $t('heatSoakLabel') }}
        <el-popover placement="bottom">
          <div class="extended-info">
            {{ $t('heatSoakInfoText') }}
          </div>
          <i
            slot="reference"
            class="el-icon-warning info-icon"
          />
        </el-popover>
      </h2>
      <span v-if="!expressProduction">{{ $t('heatSoakLabelText') }}<br></span>
      <el-switch
        v-if="!expressProduction"
        v-model="heatSoakTest"
        :active-text="$t('boolTrueText')"
        :inactive-text="$t('boolFalseText')"
        active-color="#1bafd6"
        inactive-color="black"
      />
      <el-alert
        v-if="expressProduction"
        type="info"
        show-icon
        :closable="false"
      >
        <template>
          {{ $t('heatSoakMsg') }}
        </template>
      </el-alert>
    </template>
    <template v-if="stamp && !handle.includes('optishower')">
      <h2>
        {{ $t('glassLabel') }}
        <el-popover placement="bottom">
          <div class="extended-info">
            {{ $t('glassLabelInfoText') }}
          </div>
          <i
            slot="reference"
            class="el-icon-warning info-icon"
          />
        </el-popover>
      </h2>
      {{ $t('glassLabelText') }}
      <br>
      <el-switch
        v-model="standardizationStamp"
        :active-text="$t('boolTrueText')"
        :inactive-text="$t('boolFalseText')"
        :disabled="heatSoakTest"
        active-color="#1bafd6"
        inactive-color="black"
      />
    </template>
    <h2>{{ $t('notes') }}</h2>
    <el-input
      v-model="additionalInfo"
      type="textarea"
      class="additional-info"
    />
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "Extras",
  data() {
    return {
      dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
      blockedUntil: new Date(process.env.VUE_APP_EXPRESS_BLOCKED_UNTIL)
    }
  },
  computed: {
    ...mapFields([
      "lotusCoating",
      "expressProduction",
      "standardizationStamp",
      "additionalInfo",
      "productionTime",
      "expressProductionTime",
      "handle",
      "heatSoak",
      "heatSoakTest",
      "stamp",
      "lotus",
      "glassKind",
      "frames"
    ])
  },
  watch: {
    expressProduction() {
      if (this.expressProduction) {
        this.heatSoakTest = false;
      }
    },
    heatSoak() {
      if (!this.heatSoak) {
        this.heatSoakTest = false;
      }
    },
    heatSoakTest() {
      if (this.heatSoakTest) {
        this.expressProduction = false;
        this.standardizationStamp = true;
      }
    }
  },
  methods: {
    getFormatDate() {
      return this.blockedUntil.toLocaleDateString('de-DE', this.dateFormat);
    },
    isBlocked() {
      return this.blockedUntil > new Date();
    }
  }
};
</script>
