<template>
  <div>
    <el-radio-group
      v-model="selectedGap"
      fill="#1bafd6"
    >
      <el-radio-button
        v-for="type in edges"
        :key="type.title"
        :label="type.title"
        class="card"
      >
        <img
          v-if="type.image"
          :src="getImgUrl(type)"
          class="image"
        >
        <div class="caption">
          <span class="title">{{ type.title }}</span>
          <div class="bottom clearfix">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay">
          <el-alert
            v-if="type.desc"
            style="padding: 8px 10px;border-radius: unset;"
            class="message"
            type="warning"
            :title="type.desc"
            show-icon
            :closable="false"
          />
        </div>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";
export default {
  name: "Scheibenzwischenraum",
  data() {
    return {
      edges: []
    };
  },
  computed: {
    ...mapFields([
      "selectedGap"
    ])
  },
  created() {
    this.filteredEdges();
  },
  methods: {
    filteredEdges() {
     this.edges.push({ title: i18n.t('gapSilverTitle'), desc: "", image: "Rahmen-Alu" });
     this.edges.push({ title: i18n.t('gapWhiteTitle'), desc: "", image: "Rahmen-Weiss" });
     this.edges.push({ title: i18n.t('gapBlackTitle'), desc: "", image: "Rahmen-Schwarz" });
     this.initSelectedEdge(i18n.t('gapSilverTitle'));
    },
    initSelectedEdge(title) {
      if(!this.selectedGap) this.selectedGap = title;
    },
    getImgUrl(type) {
      var file = type.image;
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + file + ".jpg");
    }
  }
};
</script>