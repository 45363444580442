<template>
  <!-- Lock -->
  <v-group>
    <v-rect
      :config="{
        x: x,
        y: y,
        width: length,
        height: height,
        fill: color,
        shadowColor: 'rgba(0, 0, 0, .1)',
        shadowBlur: 2,
        shadowOffset: { x: -2, y: 0 },
      }"
    />

    <v-line
      :config="{
        x: x + length / 2,
        y: y + 40 * ratio,
        points: [0, 0, 0, height - 80 * ratio],
        stroke: getRecessedGripColor(),
        strokeWidth: 25 * ratio,
        lineCap: 'round',
        lineJoin: 'round',
      }"
    />
  </v-group>
</template>
<script>
export default {
  name: "Lock",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#222'
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    ratio: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getRecessedGripColor() {
      if (this.isLight(this.color)) {
        return this.rgbLighter(this.color, -80);
      } else {
        return this.rgbLighter(this.color, 80);
      }
    }
  }
}
</script>