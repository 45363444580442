<template>
  <!-- Floor wall -->
  <v-line
    :config="{
      x: x,
      y: y,
      points: [
        0, 0,
        0, thickness,
        length, thickness,
        length, 0,
        (length - dimensision), -dimensision,
        thickness + dimensision, -dimensision,
      ],
      closed: true,
      fill: fill,
      shadowColor: 'rgba(0, 0, 0, .4)',
      shadowBlur: 15,
      shadowOffset: { x: 0, y: 5 },
    }"
  />
</template>
<script>
export default {
  name: "Floor",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    dimensision: {
      type: Number,
      default: 0
    },
    fill: {
      type: String,
      default: 'rgb(200, 200, 200)'
    },
    thickness: {
      type: Number,
      default: 15
    }
  }
}
</script>