<template>
  <div
    id="app"
    v-loading="loading"
    style="background: #fff;"
  >
    <el-alert
      v-if="showDiscount"
      :title="$t('discountTitle', {'discount': getDiscount() * 100 })"
      class="discount"
      type="info"
      show-icon
    >
      <template>
        {{ getDiscountRuntime() }} {{ $t('discountDescription') }}
      </template>
    </el-alert>
    <ElContainer>
      <el-main>
        <router-view
          @datachange="storeData"
          @showErrorDialog="showDialog = true"
          @finishLoading="loading = false"
        />
      </el-main>
    </ElContainer>
  </div>
</template>

<script>
import i18n from "@/js/plugins/i18n";
import { mapFields } from "vuex-map-fields";
export default {
  name: "App",
  metaInfo: {
    title: 'Glas-Star Konfigurator',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  data() {
    this.$store.state.selectedFormat = i18n.t('formRectangle');
    for (let corner of Object.keys(this.$store.state.corners)) {
      this.$store.state.corners[corner].name = i18n.t('cornerPointed');
    }

    return {
      loading: true,
      showDialog: false,
      showDiscount: false
    };
  },
  computed: {
    ...mapFields([
      "configId",
      "discount"
    ])
  },
  watch: {
    discount() {
      this.discountExists();
    },
  },
  mounted() {
    let tracking = this.$route.query.tracking;
    if (
      tracking &&
      (tracking.toLowerCase() === "true" || parseInt(tracking) === 1)
    ) {
      this.enableGtm();
    }
  },
  methods: {
    enableGtm() {
      this.$gtm?.enable(true);
    },
    discountExists() {
      this.showDiscount = this.$store.getters.discountExists();
    },
    getDiscount() {
      return this.discount?.value || this.discount;
    },
    getDiscountRuntime() {
      let text = i18n.tc('discountRuntime', 1);
      if (typeof this.discount === 'object') {
        const date = new Date(this.discount.valid_until);
        const options = { month: '2-digit', day: 'numeric', timeZone: 'UTC' };
        text = i18n.tc('discountRuntime', 2, { date: date.toLocaleDateString('de-DE', options) });
      }

      return text;
    },
    storeData(callback) {
      let configId = this.$route.query.konfig_id || this.configId;
      this.$http
        .post(
          `${process.env.VUE_APP_SAVE_CONFIG}${configId}`,
          this.$store.getters.getActiveProduct
        )
        .then(response => {
          const config_id = response.data;
          this.$store.commit("setConfigurationDetails", config_id);
          parent.postMessage(
            {
              action: "save_config",
              id: this.konfig_id,
              handle: this.$store.state.handle
            },
            "*"
          );
          parent.postMessage(
            {
              action: "configurator_save_config",
              id: this.konfig_id,
              handle: this.$store.state.handle
            },
            "*"
          );
          if (callback) { callback(); }
        });
    }
  }
};
</script>
