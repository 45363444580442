var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-path',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    data: 'm3.8966 9.6471 4-4c0.195-0.195 0.512-0.195 0.707 0 0.195 0.195 0.195 0.512 0 0.707l-3.146 3.146h10.293c0.276 0 0.5 0.224 0.5 0.5 0 0.276-0.224 0.5-0.5 0.5h-10.293l3.146 3.146c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146s-0.256-0.049-0.354-0.146l-4-4c-0.195-0.195-0.195-0.512 0-0.707zm6.1034-9.1471a9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5zm0 1a8.5 8.5 0 0 1 8.5 8.5 8.5 8.5 0 0 1-8.5 8.5 8.5 8.5 0 0 1-8.5-8.5 8.5 8.5 0 0 1 8.5-8.5z',
    fill: _vm.color,
    scale: { x: _vm.scale(), y: _vm.scale() },
    rotation: _vm.rotate
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }