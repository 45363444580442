import Vue from "vue";
import VueGtm from '@gtm-support/vue2-gtm';

if (process.env.VUE_APP_GOOGLE_PROPERTY_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_PROPERTY_ID,
    defer: false,
    enabled: false,
    debug: false,
    loadScript: true,
    trackOnNextTick: false
  });
}