var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[_c('image-component',{attrs:{"x":_vm.x,"y":_vm.y,"file":_vm.getFilePath(),"flip":_vm.flip,"vertical":"center","scale":_vm.scale}}),(_vm.active)?_c('v-line',{attrs:{"config":{
      x: _vm.flip ? _vm.x + 5 : _vm.x - 5,
      y: _vm.y,
      points: _vm.flip ? 
        [ 10, 0, -25, 0 ] : 
        [ -10, 0, 25, 0 ],
      closed: false,
      stroke: 'rgba(200, 0, 0, 1)',
      strokeWidth: 1,
    }}}):_vm._e(),(_vm.active)?_c('v-line',{attrs:{"config":{
      x: _vm.flip ? _vm.x + 5 : _vm.x - 5,
      y: _vm.offsetY,
      points: _vm.flip ? 
        [ 15, 0, 20, 0, 20, _vm.y - _vm.offsetY, 15, _vm.y - _vm.offsetY ] : 
        [ -15, 0, -20, 0, -20, _vm.y - _vm.offsetY, -15, _vm.y - _vm.offsetY ],
      closed: false,
      stroke: 'rgba(200, 0, 0, 1)',
      strokeWidth: 1,
    }}}):_vm._e(),(_vm.active)?_c('v-label',{attrs:{"config":{
      x: _vm.flip ? _vm.x + 20 : _vm.x - 30,
      y: _vm.offsetY + (_vm.y / 2) + 15,
    }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
        text: `${_vm.label} mm`,
        fontSize: 10,
        align: 'center',
        verticalAlign: 'middle',
        rotation: 270,
        width: 50
      }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }