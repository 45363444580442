<template>
  <!-- Arrow -->
  <v-path
    :config="{
      x: x,
      y: y,
      data: 'm3.8966 9.6471 4-4c0.195-0.195 0.512-0.195 0.707 0 0.195 0.195 0.195 0.512 0 0.707l-3.146 3.146h10.293c0.276 0 0.5 0.224 0.5 0.5 0 0.276-0.224 0.5-0.5 0.5h-10.293l3.146 3.146c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146s-0.256-0.049-0.354-0.146l-4-4c-0.195-0.195-0.195-0.512 0-0.707zm6.1034-9.1471a9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5zm0 1a8.5 8.5 0 0 1 8.5 8.5 8.5 8.5 0 0 1-8.5 8.5 8.5 8.5 0 0 1-8.5-8.5 8.5 8.5 0 0 1 8.5-8.5z',
      fill: color,
      scale: { x: scale(), y: scale() },
      rotation: rotate
    }"
  />
</template>
<script>
export default {
  name: "Arrow",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#222'
    },
    ratio: {
      type: Number,
      default: 1
    },
    rotate: {
      type: Number,
      default: 0
    }
  },
  methods: {
    scale() {
      return 30 * this.ratio > 2 ? 2 : 30 * this.ratio
    }
  }
}
</script>