<template>
  <el-radio-group
    v-model="lock"
    fill="#1bafd6"
    style="margin-bottom: 8px"
  >
    <el-radio-button
      :label="false"
      class="card"
      rules="required"
    >
      <HandlingSvg 
        :fill="selectedHex" 
      />
      <div class="caption">
        <span class="title">{{ $t('withoutLock') }}</span>
        <div class="bottom clearfix">
          <span class="info">{{ $t('select') }}</span>
        </div>
      </div>
      <div class="overlay" />
    </el-radio-button>
    <el-radio-button
      :label="true"
      class="card"
      rules="required"
    >
      <HandlingLockSvg
        :fill="selectedHex"
      />
      <div class="caption">
        <span class="title">{{ $t('lock') }}</span>
        <div class="bottom clearfix">
          <span class="info">{{ $t('select') }}</span>
        </div>
      </div>
      <div class="overlay" />
    </el-radio-button>
  </el-radio-group>
</template>

<script>
import { mapGetters } from "vuex";
import { mapMultiRowFields, mapFields } from "vuex-map-fields";
import HandlingSvg from "./HandlingSvg.vue";
import HandlingLockSvg from "./HandlingLockSvg.vue";

export default {
    name: "Handling",
    components: { HandlingSvg, HandlingLockSvg },
    computed: {
        ...mapGetters([
          "getPanelMultiplier",
          "getSlidingGlasLength",
          "getSlidingGlasHeight"
        ]),
        ...mapMultiRowFields(["drillings"]),
        ...mapFields([
            "length",
            "height",
            "fitting",
            "lanes",
            "lock",
            "selectedHex",
        ]),
    },
    watch: {
      length() {
        this.setDrillingPosition();
      },
      height() {
        this.setDrillingPosition();
      },
      fitting() {
        this.setDrillingPosition();
      },
      lanes() {
        this.setDrillingPosition();
      },
      lock() {
        this.setDrillingPosition();
      }
    },
    created() {
      this.setDrillingPosition();
    },
    methods: {
        addDrilling() {
          this.$store.commit("addDrilling");
        },
        removeAllDrilling() {
          while (this.drillings.length > 0) {
            this.$store.commit("removeDrilling", this.drillings.length - 1);
          }
        },
        installHandle() {
          return !this.lock || this.fitting !== 'left_right';
        },
        setDrillingPosition() {
          this.removeAllDrilling();

          if (this.installHandle()) {
            const iterations = this.getPanelMultiplier;
            for (let i = this.drillings.length; i < iterations; i++) {
              this.addDrilling();
              this.drillings[i].durchmesser = 30;
              this.drillings[i].x = this.fitting === 'left' || i === 1 ? this.getSlidingGlasLength - 80 : 80;
              this.drillings[i].y = this.getSlidingGlasHeight - 1013;
            }
          }
        }
    },
};
</script>