<template>
  <div>
    <el-radio-group
      v-model="selectedEdge"
      fill="#1bafd6"
    >
      <el-radio-button
        v-for="type in edges"
        :key="type.title"
        :label="type.title"
        class="card"
        rules="required"
      >
        <img
          v-if="type.image"
          :src="getImgUrl(type)"
          class="image"
        >
        <div class="caption">
          <span class="title">{{ type.title }}</span>
          <div class="bottom clearfix">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay">
          <el-alert
            v-if="type.desc"
            style="padding: 8px 10px;border-radius: unset;"
            class="message"
            type="warning"
            :title="type.desc"
            show-icon
            :closable="false"
          />
        </div>
      </el-radio-button>
    </el-radio-group>
    <div
      v-if="showNextBtn"
      align="right"
    >
      <el-button
        type="primary"
        :disabled="!selectedEdge"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";
export default {
  name: "Kanten",
  props: {
    showNextBtn: {
      type: Boolean,
      default: true,
      description: "Decides if the next-button will be displayed."
    }
  },
  data() {
    return {
      edges: []
    };
  },
  computed: {
    ...mapFields([
      "selectedEdge"
      ,"edgeHemmed"
      ,"priceHonedEdge"
      ,"pricePolishedEdge"
      ,"priceFacetEdge"
      ,"priceCuttingEdge"
    ])
  },
  watch:{
    priceCuttingEdge() {
      this.filteredEdges();
    },
    pricePolishedEdge() {
      this.filteredEdges();
    },
    priceFacetEdge() {
      this.filteredEdges();
    }
  },
  created() {
    this.filteredEdges();
  },
  methods: {
    next() {
      this.$emit("nextStep", 3);
    },
    filteredEdges() {
      this.edges = [];
      if(this.priceCuttingEdge && this.edges.findIndex(x => x.title == i18n.t('edgeSharpTitle')) === -1) { 
        this.edges.push({ title: i18n.t('edgeSharpTitle'), desc: "", image: "Kanten-geschnitten" });
        this.initSelectedEdge(i18n.t('edgeSharpTitle'));
      }
      if(this.edgeHemmed && this.edges.findIndex(x => x.title == i18n.t('edgeLinedTitle')) === -1) {
        this.edges.unshift({ title: i18n.t('edgeLinedTitle'), desc: i18n.t('edgeLinedDesc'), image: "Kanten-gesaeumt" });
        this.initSelectedEdge(i18n.t('edgeLinedTitle'));
      }
      if(this.priceHonedEdge && this.edges.findIndex(x => x.title == i18n.t('edgeMattTitle')) === -1) {
        this.edges.push({ title: i18n.t('edgeMattTitle'), desc: "", image: "Kanten-matt-geschliffen" });
        this.initSelectedEdge(i18n.t('edgeMattTitle'));
      }
      if(this.pricePolishedEdge && this.edges.findIndex(x => x.title == i18n.t('edgeHighGlossTitle')) === -1) {
        this.edges.push({ title: i18n.t('edgeHighGlossTitle'), desc: "", image: "Kanten-poliert" });
        this.initSelectedEdge(i18n.t('edgeHighGlossTitle'));
      }
      if(this.priceFacetEdge && this.edges.findIndex(x => x.title == i18n.t('edgeFacetTitle')) === -1) {
        this.edges.push({ title: i18n.t('edgeFacetTitle'), desc: "", image: "Kanten-facette20" });
        this.initSelectedEdge(i18n.t('edgeFacetTitle'));
      }
    },
    initSelectedEdge() {
      if(!this.selectedEdge) this.selectedEdge = this.edges[0].title;
    },
    getImgUrl(type) {
      var file = type.image;
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + file + ".jpg");
    }
  }
};
</script>