<template>
  <div>
    <el-row>
      <h4>{{ $t('timeOfProduktion') }}</h4>
      {{ productionTime }}
    </el-row>
    <el-row v-if="terraceGlasses.length > 1">
      <h4>{{ $t('lid') }}</h4>
      <el-col>
        <el-radio-group
          v-model="selectedKlemmdeckel"
          fill="#1bafd6"
        >
          <el-radio-button
            :label="undefined"
            class="card extra"
            rules="required"
          >
            <img
              :src="getImgUrl({type: 'ohne', color: ''})"
              class="image"
            >
            <div class="caption">
              <span class="title">{{ $t('withoutLid') }}</span>
              <div class="bottom clearfix">
                <span class="info">{{ $t('select') }}</span>
              </div>
            </div>
            <div class="overlay" />
          </el-radio-button>
          <el-radio-button
            v-for="lid in lids"
            :key="lid.title"
            :label="lid"
            class="card extra"
            rules="required"
          >
            <img
              :src="getImgUrl(lid)"
              class="image"
            >
            <div class="caption">
              <span class="title">{{ lid.title }}</span>
              <div class="bottom clearfix">
                <span class="info">{{ $t('select') }}</span>
              </div>
            </div>
            <div class="overlay" />
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row v-if="selectedKlemmdeckel">
      <el-col>
        <h4>{{ $t('bracketQ') }}</h4>
        <el-switch
          v-model="haltewinkel"
          :active-text="$t('boolTrueText')"
          :inactive-text="$t('boolFalseText')"
          active-color="#1bafd6"
          inactive-color="black"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
export default {
  name: "TerassenExtras",
  data() {
    return {
      haltewinkel: false
    }
  },
  computed: {
    ...mapMultiRowFields(["terraceGlasses"]),
    ...mapFields([
      "barWidthInMm",
      "productionTime",
      "selectedHaltewinkel",
      "selectedKlemmdeckel"
    ]),
    lids() {
      return this.$store.getters.filterAccessoriesBy([
        ['size', this.barWidthInMm],
        ['type', 'klemmdeckel']
      ]);
    }
  },
  watch: {
    haltewinkel() {
      if (this.haltewinkel && this.selectedKlemmdeckel) {
        this.selectedHaltewinkel = this.$store.getters.filterAccessoriesBy([
          ['color', this.selectedKlemmdeckel.color],
          ['size', this.selectedKlemmdeckel.size],
          ['type', 'haltewinkel']
        ])[0];
      } else {
        this.selectedHaltewinkel = undefined;
      }
    }
  },
  mounted() {
    if (this.selectedHaltewinkel) {
      this.haltewinkel = true;
    }
  },
  methods: {
    getImgUrl(lid) {
      var file = `${lid.type}-${lid.color}`;
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + file.replace(/-$/, '') + ".jpg");
    }
  }
};
</script>