<template>
  <div>
    <label 
      v-if="handle && handle.includes('isolierglas')"
      class="el-form-item__label"
    >
      {{ $t('installThicknessTitle') }}
    </label>
    <el-radio-group
      v-model="glassThickness"
      fill="#1bafd6"
      @change="selectProduct(glassThickness)"
    >
      <el-radio-button
        v-for="thickness in sortedProducts"
        :key="thickness"
        :label="thickness"
        class="simple"
      >
        {{ thickness }} mm {{ thicknessTitle }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "Glasstaerke",
  props: {
    thicknessTitle: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapFields([
      "glassThickness",
      "minThickness",
      "maxThickness",
      "availableProducts",
      "handle"
    ]),
    sortedProducts() {
      let thicknesses = [];
      for (let product of this.availableProducts) {
        thicknesses.push(product.glassThickness);
      }
      return thicknesses.sort((a, b) => a - b);
    }
  },
  methods: {
    selectProduct(selectedThickness) {
      let result = this.availableProducts.find(obj => {
        return obj.glassThickness === selectedThickness;
      });
      this.$store.commit("setActiveProduct", result);
    }
  }
};
</script>

<style lang="css" scoped></style>
