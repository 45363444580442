<template>
  <v-rect
    :config="{
      x: x,
      y: y - 25,
      width: length,
      height: height,
      fill: darkerColor
    }"
  />
</template>
<script>
export default {
  name: "PanelTopDown",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "rgba(152, 165, 186, .5)"
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
  },
  computed: {
    darkerColor() {
      return this.rgbLighter(this.color, -80);
    },
  }
}
</script>