<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :mode="mode"
    :rules="rules"
    :vid="vid"
  >
    <el-form-item
      :class="[className ? className : '']"
      :label="label"
      :error="errors[0]"
      :required="required"
    >
      <slot />
      <template 
        v-if="$slots.label" 
        slot="label"
      >
        <slot name="label" />
      </template>
    </el-form-item>
  </validation-provider>
</template>

<script>
export default {
  name: "FormItemWrapper",
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      required: true
    },
    mode: {
      type: String,
      default: "aggressive"
    },
    vid: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    useSlot: {
      type: Boolean,
      default: false
    }
  }
};
</script>
