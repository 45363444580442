<template>
  <form-item-wrapper
    :label="$t('lanes')"
    :rules="{
      required: true
    }"
  >
    <el-popover 
      placement="right"
      trigger="hover"
      :width="200"
    >
      <div class="extended-info">
        {{ $t('lanesInfo') }}
      </div>
      <i
        slot="reference"
        class="el-icon-info info-icon"
      />
    </el-popover>
    
    <el-radio-group v-model="lanes">
      <el-radio-button
        v-for="(lane, index) in maxLanes - 1" 
        :key="lane"
        :label="index + minLanes" 
        :disabled="isDisabled(index + minLanes)"
      />
    </el-radio-group>
    <el-tag size="mini">
      {{ $t('glassDimension') }}: {{ getGlassDimesision }} mm
    </el-tag>
  </form-item-wrapper>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "NumberOfLanes",
  props: {
    minLanes: {
      type: Number,
      default: 2
    },
    maxLanes: {
      type: Number,
      default: 5
    },
  },
  computed: {
    ...mapGetters([
      "getPanelMultiplier",
      "getMaxWidth",
      "getSlidingGlasLength",
      "getSlidingGlasHeight"
    ]),
    ...mapFields([
      "minWidth",
      "lanes",
      "length"
    ]),
    getGlassDimesision() {
      return `${this.getSlidingGlasLength} x ${this.getSlidingGlasHeight}`;
    },
    getMinWidth() {
      return this.minWidth * this.lanes * this.getPanelMultiplier;
    }
  },
  methods: {
    isDisabled(lanes) {
      const minWidth = this.getMinWidth / this.lanes;
      const maxWidth = this.getMaxWidth * this.getPanelMultiplier;

      return this.length < (minWidth * lanes) || this.length > (maxWidth * lanes);
    }
  }
}
</script>