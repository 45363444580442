<template>
  <el-form>
    <el-collapse
      v-model="activeStep"
      accordion
    >
      <el-collapse-item
        :name="1"
        :disabled="blockSteps"
      >
        <template slot="title">
          <h3>{{ $t('format') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 1"
            class="status-icon"
            icon="check"
            @resetStep="maxStep = 1"
          />
        </template>
        <Format
          v-if="glassKind != 'hust' && frames.length == 0"
          @nextStep="nextStep($event)"
          @updateValidation="keepFocus($event)"
        />
        <Protect
          v-if="glassKind == 'hust' && handle && !handle.includes('simple')"
          @nextStep="nextStep($event)"
          @updateValidation="keepFocus($event)"
        />
        <FrameFormat
          v-if="frames.length > 0"
          @nextStep="nextStep($event)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-if="glassKind != 'hust' && glassKind != 'ISO' && handle && !handle.includes('simple') && frames.length == 0"
        :name="2"
        :disabled="activeStep < 2"
      >
        <template slot="title">
          <h3>{{ $t('edges') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 2"
            class="status-icon"
            icon="check"
          />
        </template>
        <Kanten 
          @nextStep="nextStep(2)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-if="selectedFormat != 'Ellipse' && glassKind != 'hust' && glassKind != 'ISO' && handle && !handle.includes('simple') && frames.length == 0"
        :name="3"
        :disabled="activeStep < 3"
      >
        <template slot="title">
          <h3>{{ $t('corners') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 3"
            class="status-icon"
            icon="check"
          />
        </template>
        <Ecken 
          @nextStep="nextStep($event)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-if="glassKind != 'hust' && glassKind != 'ISO' && handle && !handle.includes('simple') && priceHole && frames.length == 0"
        :name="4"
        :disabled="activeStep < 4"
      >
        <template slot="title">
          <h3>{{ $t('drillings') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 4"
            class="status-icon"
            icon="check"
          />
        </template>
        <Bohrungen
          @nextStep="nextStep(4)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-if="farbig"
        :name="5"
        :disabled="activeStep < 5"
      >
        <template slot="title">
          <h3>{{ $t('painting') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 5"
            class="status-icon"
            icon="check"
          />
        </template>
        <Lackierung
          @nextStep="nextStep(5)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-if="glassKind == 'ISO' && frames.length == 0"
        :name="6"
        :disabled="activeStep < 6"
      >
        <template slot="title">
          <h3>{{ $t('glassGap') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 6"
            class="status-icon"
            icon="check"
          />
        </template>
        <Scheibenzwischenraum />
        <div align="right">
          <el-button
            type="primary"
            @click="nextStep(6)"
          >
            {{ $t('nextBtn') }}
          </el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="frames.length > 0"
        :name="7"
        :disabled="activeStep < 7"
      >
        <template slot="title">
          <h3>{{ $t('frames') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 7"
            class="status-icon"
            icon="check"
          />
        </template>
        <Rahmen
          @nextStep="nextStep(7)"
          @updateValidation="keepFocus($event)"
        />
      </el-collapse-item>
      <el-collapse-item
        :name="8"
        :disabled="activeStep < 8"
      >
        <template slot="title">
          <h3>{{ $t('extras') }}</h3>
          <font-awesome-icon
            v-if="maxStep > 8"
            class="status-icon"
            icon="check"
          />
        </template>
        <Extras />
        <div align="right">
          <el-button
            type="primary"
            data-iframe-height
            :loading="loading"
            @click="checkout"
          >
            {{ chkoutBtnText }}
          </el-button>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-form>
</template>

<script>
import Format from "@/components/Layout/Format_konva_img.vue";
import Protect from "@/components/Layout/Protect_konva.vue";
import FrameFormat from "@/components/Layout/Frame_konva.vue";
import Ecken from "@/components/Layout/Ecken.vue";
import Rahmen from "@/components/Layout/Rahmen.vue";
import Kanten from "@/components/Layout/Kanten.vue";
import Bohrungen from "@/components/Layout/Bohrungen.vue";
import Lackierung from "@/components/Gestaltung/Folienfarbe.vue";
import Scheibenzwischenraum from "@/components/Layout/Scheibenzwischenraum.vue";
import Extras from "@/components/Extras.vue";
import { mapFields } from "vuex-map-fields";

export default {
  name: "GlassKonfigurator",
  components: {
    Format,
    FrameFormat,
    Protect,
    Ecken,
    Rahmen,
    Kanten,
    Bohrungen,
    Lackierung,
    Scheibenzwischenraum,
    Extras
  },
  props: {
    whatIf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chkoutBtnText: this.$t('chkoutDialogBtn'),
      blockSteps: false,
      loading: false
    };
  },
  computed: {
    ...mapFields([
      "farbig",
      "activeStep",
      "maxStep",
      "selectedFormat",
      "handle",
      "glassKind",
      "configId",
      "priceHole",
      "frames"
    ]),
  },
  created() {
    this.$emit("loadSavedConfig");
  },
  methods: {
    nextStep(triggeringStep) {
      if (triggeringStep === this.maxStep) {
        this.maxStep++;
        this.previousMaxStep++;
        if (this.frames.length && triggeringStep === 1) {
          this.maxStep += 5;
          this.previousMaxStep += 5;
        } else if (this.glassKind === "ISO" && triggeringStep === 1) {
          this.maxStep += 4;
          this.previousMaxStep += 4;
        } else if (this.glassKind === "hust" || (this.handle && this.handle.includes('simple'))) {
          this.maxStep += 6;
          this.previousMaxStep += 6;
        } else if (this.selectedFormat === "Ellipse" && triggeringStep === 2) {
          this.maxStep++;
          this.previousMaxStep++;
        } 
        if (!this.priceHole && (triggeringStep === 3 || this.maxStep === 3)) {
          this.maxStep++;
          this.previousMaxStep++;
        }
        
        if (this.farbig && !(this.glassKind === "ISO") && triggeringStep === 5) {
          this.maxStep += 2;
          this.previousMaxStep += 2;
        } else if (!this.farbig && (triggeringStep === 4 || this.maxStep === 4)) {
          this.maxStep += 3;
          this.previousMaxStep += 3;
        }

        if(triggeringStep === 6) {
          this.maxStep++;
          this.previousMaxStep++;
        }
      }
      if (this.frames.length && triggeringStep === 1) {
        this.activeStep += 5;
      } else if (this.glassKind === "ISO" && triggeringStep === 1) {
        this.activeStep += 4;
      } else if (this.glassKind === "hust" || (this.handle && this.handle.includes('simple'))) {
        this.activeStep += 6;
      } else if (this.selectedFormat === "Ellipse" && triggeringStep === 2) {
        this.activeStep++;
      } 
      if (!this.priceHole && (triggeringStep === 3 || this.activeStep === 3)) {
        this.activeStep++;
      }
      
      if (this.farbig && !(this.glassKind === "ISO") && triggeringStep === 5) {
        this.activeStep+=2;
      } else if (!this.farbig && (triggeringStep === 4 || this.activeStep === 4)) {
        this.activeStep+=3;
      }

      if(triggeringStep === 6) {
          this.activeStep++;
        }
      this.activeStep++;
    },
    keepFocus(validationProvider) {
      if (typeof validationProvider === "object") {
        this.blockSteps = !validationProvider.valid;
      } else {
        this.blockSteps = !validationProvider;
      }
    },
    checkout() {
      this.loading = true;
      this.chkoutBtnText = this.$t('chkoutDialogBtnLoading');
      const items = this.$store.getters.getShopifyGlassKonfiguratorLineItems;

      if (this.whatIf) {
        this.$emit("postLineItems", null, items);
        this.chkoutBtnText = this.$t('chkoutDialogBtn'),
        this.loading = false;
      } else {
        this.$http
          .post(
            process.env.VUE_APP_CHECKOUT,
            this.$store.getters.getShopifyGlassKonfiguratorProduct
          )
          .then(response => {
            this.$emit("postLineItems", response.data, items);
          })
          .catch(() => {
            // TODO: SigNoz error captcha
            this.$emit("notify", {
              title: this.$t('cartError'),
              message: this.$t('addToCartError'),
              type: 'error'
            });
          }).finally(() => {
            this.chkoutBtnText = this.$t('chkoutDialogBtn'),
            this.loading = false;
          });
      }
    }
  }
};
</script>
