<template>
  <form-item-wrapper
    :label="$t('openingDirection')"
    :rules="{
      required: true
    }"
  >
    <el-popover 
      placement="right"
      trigger="hover"
      :width="200"
    >
      <div class="extended-info">
        {{ $t('openingDirectionInfo') }}
      </div>
      <i
        slot="reference"
        class="el-icon-info info-icon"
      />
    </el-popover>

    <el-radio-group v-model="fitting">
      <el-radio-button 
        label="left" 
        :disabled="isDisabled(1)"
      >
        <i class="el-icon-back" />
      </el-radio-button>

      <el-radio-button
        label="right" 
        :disabled="isDisabled(1)"
      >
        <i class="el-icon-right" />
      </el-radio-button>

      <el-radio-button 
        label="left_right" 
        :disabled="isDisabled(2)"
      >
        <i class="el-icon-back" /> <i class="el-icon-right" />
      </el-radio-button>
    </el-radio-group>
  </form-item-wrapper>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "OpeningDirection",
  computed: {
    ...mapGetters([
      "getPanelMultiplier",
      "getMaxWidth"
    ]),
    ...mapFields([
      "fitting",
      "lanes",
      "length",
      "minWidth"
    ]),
    getMinWidth() {
      return this.minWidth * this.lanes * this.getPanelMultiplier;
    }
  },
  methods: {
    isDisabled(panelMultiplier) {
      const panelDivider = this.fitting === "left_right" ? 2 : 1; 
      const minWidth = this.getMinWidth / panelDivider;
      const maxWidth = this.getMaxWidth * this.lanes;

      return this.length < (minWidth * panelMultiplier) || this.length > (maxWidth * panelMultiplier);
    }
  }
}
</script>