import Vue from "vue";
import Router from "vue-router";
import VueMeta from 'vue-meta';
import ConfiguratorContainer from "@/views/ConfiguratorContainer.vue";
import ErrorPage from "@/views/ErrorPage.vue";

import i18n from "./plugins/i18n";

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  routes: [
    {
      path: "*",
      redirect: "/de/404"
    },
    {
      path: '/',
      redirect: '/de/'
    },
    {
      path: '/dusch-konfigurator',
      redirect: '/de/dusch-konfigurator'
    },
    {
      path: '/schiebewand-konfigurator',
      redirect: '/de/schiebewand-konfigurator'
    },
    {
      path: '/terassendach-konfigurator',
      redirect: '/de/terassendach-konfigurator'
    },
    {
      path: "/:lang/",
      component: ConfiguratorContainer
    },
    {
      path: "/:lang/dusch-konfigurator",
      component: ConfiguratorContainer
    },
    {
      path: "/:lang/schiebewand-konfigurator",
      component: ConfiguratorContainer
    },
    {
      path: "/:lang/terassendach-konfigurator",
      component: ConfiguratorContainer
    },
    {
      path: "/de/404",
      component: ErrorPage
    }
  ]
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  let toRedirect = to.fullPath;
  if (!["nl", "de"].includes(lang)) return next({ path: toRedirect.replace(lang, "de") });
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
  return next();
})

export default router