var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',{ref:"node",attrs:{"config":{ draggable: false }}},[_c('v-rect',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      width: _vm.length,
      height: _vm.height,
      fillLinearGradientStartPoint: { x: (_vm.height / 2), y: 0 },
      fillLinearGradientEndPoint: { x: 0, y: (_vm.length / 2) },
      fillLinearGradientColorStops: [
        0, _vm.lighterColor,
        0.13, _vm.glassColor,
        0.37, _vm.lighterColor,
        0.57, _vm.lighterColor,
        0.79, _vm.glassColor,
        1, _vm.glassColor
      ],
    }}}),_c('v-rect',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y + (_vm.height - _vm.bottomFrame),
      width: _vm.length,
      height: _vm.bottomFrame,
      fill: _vm.frameColor,
      shadowColor: 'rgba(120, 120, 120, .8)',
      shadowBlur: 3,
      shadowOffset: { x: _vm.shadowOffset, y: 0 },
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }