export default extend => {
  extend("exists_in_json", {
    validate: (value, { selector, json }) => {
      var result = json.filter(data => {
        return data[selector] == value;
      });
      return result.length == 1;
    },
    params: ["selector", "json"]
  });
  extend("lower_dimension", {
    validate: (x, { y, minX, minY }) => {
      return (x >= minX && y >= minY) 
        || (x >= minY && y >= minX);
    },
    params: ["y", "minX", "minY", "maxX", "maxY"]
  });
  extend("greater_dimension", {
    validate: (x, { y, maxX, maxY }) => {
      return (x <= maxX && y <= maxY) 
        || (x <= maxY && y <= maxX);
    },
    params: ["y", "minX", "minY", "maxX", "maxY"]
  });
  extend("inside_values_or_gt", {
    validate: (value, { diameters, gt }) => {
      return diameters.indexOf(value) != -1 || value >= gt;
    },
    params: ["diameters", "gt"]
  });
  extend("point_in_form", {
    validate: (value, { x, y, form }) => {
      if(form.length == 2) { // ellipse
        var ha = form[0], kb = form[1];
        var p = (parseInt(Math.pow((x - ha), 2)) / parseInt(Math.pow(ha, 2))) + (parseInt(Math.pow((y - kb), 2)) / parseInt(Math.pow(kb, 2)));
        return p<=1;
      }

      var g=form.length-1,b=form[0];
      if(b[0]!==form[g][0]&&b[1]!==form[g][1])throw Error("First and last coordinates must be match");
      for(var h=0,a=b[0]-x,c=b[1]-y,k=0;k<g;k++){b=form[k+1];var d=b[1]-y;if(0>c&&0>d||0<c&&0<d)c=d,a=b[0]-x;else{b=b[0]-x;if(0<d&&0>=c)if(a=a*d-b*c,0<a)h+=1;else{if(0===a)return !0}else if(0<c&&0>=d)if(a=a*d-b*c,0>a)h+=1;else{if(0===a)return !0}else if(0===d&&0>c){if(a=a*d-b*c,0===a)return !0}else if(0===c&&0>d){if(a=a*d-b*c,0===a)return !0}else if(0===c&&0===d&&(0>=b&&0<=a||0>=a&&0<=b))return !0;c=d;a=b}}
      return 0===h%2?!1:!0;
    },
    params: ["x", "y", "form"]
  });
  extend("max_terrace_glass_width", {
    validate: (value, { currentValue, maxValue }) => {
      return currentValue <= maxValue;
    },
    params: ["currentValue", "maxValue"]
  });
};
