<template>
  <!--
    S T A R T   F R A M E
    -------------------------------------------------------------------------
  -->
  <v-layer>
    <v-rect
      :config="{
        x: x + (thickness / 2 * ratio),
        y: y + (thickness / 2 * ratio),
        width: (length - (thickness)) * ratio,
        height: (height - (thickness)) * ratio,
        stroke: farbe,
        strokeWidth: thickness * ratio,
        shadowColor: 'rgba(0, 0, 0, .1)',
        shadowBlur: 5,
        shadowOffset: { x: 0, y: -5 },
      }"
    />

    <div>
      <v-line
        :config="{
          x: x - 15,
          y: y,
          points: [5, 0, 0, 0, 0, height * ratio, 5, height * ratio],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x - 20,
          y: 20 + (height * ratio / 2) + 30
        }"
      >
        <v-tag :config="{ fill: '#fff', rotation: 270 }" />
        <v-text
          :config="{
            text: `${inputHeight} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            rotation: 270,
            width: 50
          }"
        />
      </v-label>

      <v-line
        :config="{
          x: x,
          y: (height * ratio) + y + 15,
          points: [ length * ratio, -5, length * ratio, 0, 0, 0, 0, -5 ],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x + (length * ratio) / 2 - 25,
          y: height * ratio + y + 10
        }"
      >
        <v-tag :config="{ fill: '#fff' }" />
        <v-text
          :config="{
            text: `${inputLength} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            width: 50
          }"
        />
      </v-label>
    </div>
  </v-layer>
  <!--
    -------------------------------------------------------------------------
    F R A M E   E N D
  -->
</template>
<script>
export default {
  name: "Frame",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    inputLength: {
      type: Number,
      default: 0
    },
    inputHeight: {
      type: Number,
      default: 0
    },
    ratio: {
      type: Number,
      default: 1
    },
    farbe: {
      type: String,
      default: "#000"
    },
    thickness: {
      type: Number,
      default: 30
    }
  }
}
</script>
