var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"cornerObserver",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',[_vm._l((_vm.getCorners),function(corner){return _c('el-row',{key:corner},[_c('el-col',[_c('el-card',{attrs:{"shadow":"never"}},[_c('div',{staticClass:"small-box",class:_vm.corners[corner].name === _vm.$t('cornerPointed') ? 'isSelected' : '',on:{"click":function($event){_vm.corners[corner].name = _vm.$t('cornerPointed')}}},[_c('img',{style:(_vm.getRotationForEdge(corner)),attrs:{"src":_vm.getImgUrl('corner_standard', _vm.corners[corner].name === _vm.$t('cornerPointed')),"alt":""}})]),_c('div',{staticClass:"small-box",class:_vm.corners[corner].name === _vm.$t('cornerBevelled') ? 'isSelected' : '',on:{"click":function($event){_vm.corners[corner].name = _vm.$t('cornerBevelled')}}},[_c('img',{style:(_vm.getRotationForEdge(corner)),attrs:{"src":_vm.getImgUrl(
                  'corner_pushed',
                  _vm.corners[corner].name === _vm.$t('cornerBevelled')
                ),"alt":""}})]),_c('div',{staticClass:"small-box",class:_vm.corners[corner].name === _vm.$t('cornerRounded') ? 'isSelected' : '',on:{"click":function($event){_vm.corners[corner].name = _vm.$t('cornerRounded')}}},[_c('img',{style:(_vm.getRotationForEdge(corner)),attrs:{"src":_vm.getImgUrl('corner_rounded', _vm.corners[corner].name === _vm.$t('cornerRounded')),"alt":""}})]),(_vm.corners[corner].name != _vm.$t('cornerPointed'))?_c('el-row',{staticStyle:{"margin-top":"10px","margin-bottom":"-10"},attrs:{"gutter":12}},[(_vm.corners[corner].name === _vm.$t('cornerBevelled'))?_c('el-col',{attrs:{"span":12}},[_c('form-item-wrapper',{attrs:{"label":"X","rules":{
                  required: true,
                  numeric: true,
                  min_value: 1
                }}},[_c('el-input',{model:{value:(_vm.corners[corner].x),callback:function ($$v) {_vm.$set(_vm.corners[corner], "x", $$v)},expression:"corners[corner].x"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1):_vm._e(),(_vm.corners[corner].name === _vm.$t('cornerBevelled'))?_c('el-col',{attrs:{"span":12}},[_c('form-item-wrapper',{attrs:{"label":"Y","rules":{
                  required: true,
                  numeric: true,
                  min_value: 1
                }}},[_c('el-input',{model:{value:(_vm.corners[corner].y),callback:function ($$v) {_vm.$set(_vm.corners[corner], "y", $$v)},expression:"corners[corner].y"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1):_c('el-col',{attrs:{"span":12}},[_c('form-item-wrapper',{attrs:{"label":"r","required":true,"rules":{
                  required: true,
                  numeric: true,
                  min_value: 1
                }}},[_c('el-input',{model:{value:(_vm.corners[corner].r),callback:function ($$v) {_vm.$set(_vm.corners[corner], "r", $$v)},expression:"corners[corner].r"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1)],1):_vm._e()],1)],1)],1)}),_c('div',{attrs:{"align":"right"}},[_c('el-button',{attrs:{"type":"primary","disabled":invalid},on:{"click":function($event){return _vm.next()}}},[_vm._v(" "+_vm._s(_vm.$t('nextBtn'))+" ")])],1)],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }