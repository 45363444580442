var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image)?_c('v-image',{ref:"image",attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    image: _vm.image,
    scale: { x: (_vm.flip ? -1 : 1) * _vm.scale, y: 1 * _vm.scale },
    offset: _vm.offset,
  }}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }