<template>
  <div>
    <el-row>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="10"
        :xl="8"
        class="terraceWidth"
      >
        <h4>
          {{ $t('terraceWidthQ') }} 
          <el-button
            icon="el-icon-info"
            class="help-icon"
            circle
            @click="startTour(1)"
          />
        </h4>
        <el-input-number
          v-model="terraceWidth"
          size="small"
          :min="minWidth * terraceGlasses.length + profileWidthSum"
        />    
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="10"
        :xl="8"
        class="terraceGlassesLength"
      >
        <h4>
          {{ $t('terraceGlassLengthQ') }} 
          <el-button
            icon="el-icon-info"
            class="help-icon"
            circle
            @click="startTour(2)"
          />
        </h4>
        <el-input-number
          :value="terraceGlasses.length"
          size="small"
          :min="1"
          @change="adaptGlasses($event)"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xs="24"
        :sm="8"
        :md="8"
        :lg="6"
        :xl="6"
        class="individualTerraceGlassSizes"
      >
        <h3>
          {{ $t('individualTerraceGlassSizesQ') }}
          <el-button
            icon="el-icon-info"
            class="help-icon"
            circle
            @click="startTour(3)"
          />
        </h3>
        <el-switch
          v-model="individualTerraceGlassSizes"
          :active-text="$t('boolFalseText')"
          :inactive-text="$t('boolTrueText')"
          active-color="#1bafd6"
          inactive-color="black"
          :disabled="terraceGlasses.length == 1"
          @change="$event ? null : selectedGlass = 0; updateGlasses()"
        />
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
        :md="8"
        :lg="6"
        :xl="6"
        class="is80Milimeters"
      >
        <h3>
          {{ $t('girderStrenghQ') }}
          <el-button
            icon="el-icon-info"
            class="help-icon"
            circle
            @click="startTour(4)"
          />
        </h3>
        <el-switch
          v-model="is80Milimeters"
          active-text="80 mm"
          inactive-text="60 mm"
          active-color="#1bafd6"
          inactive-color="black"
        />
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
        :md="8"
        :lg="6"
        :xl="6"
        class="isAluminium"
      >
        <h3>
          {{ $t('substructureQ') }}
          <el-button
            icon="el-icon-info"
            class="help-icon"
            circle
            @click="startTour(5)"
          />
        </h3>
        <el-switch
          v-model="isAluminium"
          :active-text="$t('aluminium')"
          :inactive-text="$t('wood')"
          active-color="#1bafd6"
          inactive-color="black"
        />
      </el-col>
    </el-row>
    <el-row
      class="TerraceGlassSizes"
      style="margin-bottom: 8px;"
    >
      <el-radio-group
        v-if="individualTerraceGlassSizes"
        v-model="selectedGlass"
        style="padding-top: 12px"
        fill="#1bafd6"
      >
        <el-radio-button
          v-for="(glass, index) in terraceGlasses"
          :key="index"
          :label="index"
          :disabled="!individualTerraceGlassSizes && index != 0"
        >
          {{ $tc('pane', 1) }} {{ index + 1 }}
        </el-radio-button>
      </el-radio-group>
      <h4>
        {{ $t('paneSize') }}
        <el-button
          icon="el-icon-info"
          class="help-icon"
          circle
          @click="startTour(6)"
        />
      </h4>
      <el-col
        :xs="12"
        :sm="5"
        :md="5"
        :lg="4"
        :xl="3"
      >
        <!-- TODO @AXEl fix NaN error in Validation -->
        <form-item-wrapper
          vid="widthValidator"
          :label="$t('width')"
          :required="true"
          :rules="{
            required: true,
            between: { min: minWidth, max: maxWidth },
            max_terrace_glass_width: {currentValue: currentAccumulatedWidthOfGlassinMiliMeters, maxValue: maxGlassWidthInMiliMeters}
          }"
        >
          <el-input-number
            v-model="terraceGlasses[selectedGlass].width"
            size="small"
            :disabled="!individualTerraceGlassSizes"
            :precision="0"
            @change="updateGlasses()"
          >
            <span slot="suffix">mm</span>
          </el-input-number>
        </form-item-wrapper>
      </el-col>
      <el-col
        :xs="12"
        :sm="5"
        :md="5"
        :lg="4"
        :xl="3"
      >
        <form-item-wrapper
          :label="$t('length')"
          :required="true"
          :rules="{
            required: true,
            between: { min: minHeight, max: maxHeight }
          }"
        >
          <el-input-number
            v-model="terraceGlasses[selectedGlass].height"
            size="small"
            :disabled="!terraceWidth"
            :precision="0"
            @change="updateGlasses(selectedGlass)"
          >
            <span slot="suffix">mm</span>
          </el-input-number>
        </form-item-wrapper>
      </el-col>
      <el-col
        :xs="12"
        :sm="6"
        :md="6"
        :lg="5"
        :xl="3"
      >
        <form-item-wrapper
          :label="$t('weight')"
          :rules="{ required: true }"
        >
          <el-input-number
            :value="getAccumulatedWeightOfGlassesInKiloGram"
            size="small"
            :disabled="true"
            :precision="1"
          />
        </form-item-wrapper>
      </el-col>
    </el-row>
    <el-alert
      v-if="individualTerraceGlassSizes && currentAccumulatedWidthOfGlassinMiliMeters < maxGlassWidthInMiliMeters"
      style="margin-top: 12px"
      type="warning"
      show-icon
      :closable="false"
    >
      {{ $tc('individualTerraceGlassSizesMsg', 1, {width: maxGlassWidthInMiliMeters - currentAccumulatedWidthOfGlassinMiliMeters}) }}
    </el-alert>
    <v-stage
      v-if="!isNaN(terraceGlasses[0].width) && !isNaN(ratio)"
      ref="stage"
      :config="stageSize"
    >
      <!--
        S T A R T
        T E R R A C E
        -------------------------------------------------------------------------
      -->
      <v-layer>
        <v-group
          v-for="(glass, index) of terraceGlasses"
          :key="index"
        >
          <v-rect
            :config="{
              x: calculateXpos(index, true),
              y: 20,
              width: parseInt(glass.width) * ratio,
              height: parseInt(glass.height) * ratio,
              fillLinearGradientStartPoint: { x: (parseInt(glass.height) / 2) * ratio, y: 0 },
              fillLinearGradientEndPoint: { x: 20, y: (parseInt(glass.width) / 2) * ratio },
              fillLinearGradientColorStops: [0, '#b1c6e1', 1, '#7698c5'],
              strokeWidth: 0,
              strokeScaleEnabled: false,
              preventDefault: false
            }"
          />
          <v-rect
            v-if="index > 0 && index < terraceGlasses.length"
            :config="{
              x: calculateXpos(index, false),
              y: 20,
              width: barWidthInMm * ratio,
              height: parseInt(glass.height) * ratio,
              fill: '#E5E5E7',
              strokeWidth: 0,
              strokeScaleEnabled: false,
              preventDefault: false
            }"
          />
        </v-group>
        <v-rect
          :config="{
            x: 20,
            y: 20,
            width: barWidthInMm * ratio,
            height: parseInt(terraceGlasses[0].height) * ratio,
            fill: '#E5E5E7',
            strokeWidth: 0,
            strokeScaleEnabled: false,
            preventDefault: false
          }"
        />
        <v-rect
          :config="{
            x: calculateXpos(terraceGlasses.length, false),
            y: 20,
            width: barWidthInMm * ratio,
            height: parseInt(terraceGlasses[terraceGlasses.length - 1].height) * ratio,
            fill: '#E5E5E7',
            strokeWidth: 0,
            strokeScaleEnabled: false,
            preventDefault: false
          }"
        />
      </v-layer>
      <!--
        -------------------------------------------------------------------------
        T E R R A C E
        E N D
      -->
      <v-layer>
        <v-group
          v-for="(glass, index) of terraceGlasses"
          :key="index"
        >
          <v-line
            :config="{
              x: calculateXpos(index, true),
              y: 7,
              points: [
                parseInt(glass.width) * ratio,
                10,
                parseInt(glass.width) * ratio,
                0,
                0,
                0,
                0,
                10
              ],
              stroke: 'black',
              strokeWidth: 1,
              strokeScaleEnabled: false
            }"
          />
          <v-label
            :config="{
              x: calculateXpos(index, true) + parseInt(glass.width) * ratio / 2 - 15,
              y: 3
            }"
          >
            <v-tag :config="{ fill: '#fff' }" />
            <v-text
              :config="{
                text: glass.width,
                fontSize: 10,
                align: 'center',
                verticalAlign: 'middle',
                width: 30
              }"
            />
          </v-label>
        </v-group>
        <v-line
          :config="{
            x: 5,
            y: 20,
            points: [10, 0, 0, 0, 0, parseInt(terraceGlasses[0].height) * ratio, 10, parseInt(terraceGlasses[0].height) * ratio],
            stroke: 'black',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
        <v-line
          :config="{
            x: 20,
            y: parseInt(terraceGlasses[0].height) * ratio + 20 + 15,
            points: [
              parseInt(terraceWidth) * ratio,
              -10,
              parseInt(terraceWidth) * ratio,
              0,
              0,
              0,
              0,
              -10
            ],
            stroke: 'black',
            strokeWidth: 1,
            strokeScaleEnabled: false
          }"
        />
        <v-label
          :config="{
            x: 1,
            y: 20 + (parseInt(terraceGlasses[0].height) / 2) * ratio + 15 - (5 * ratio)
          }"
        >
          <v-tag :config="{ fill: '#fff', rotation: 270 }" />
          <v-text
            :config="{
              text: terraceGlasses[0].height,
              fontSize: 10,
              align: 'center',
              verticalAlign: 'middle',
              rotation: 270,
              width: 30
            }"
          />
        </v-label>
        <v-label
          :config="{
            x: 20 + (parseInt(terraceWidth) * ratio) / 2 - 15 - (5 * ratio),
            y: parseInt(terraceGlasses[0].height) * ratio + 20 + 10
          }"
        >
          <v-tag :config="{ fill: '#fff' }" />
          <v-text
            :config="{
              text: terraceWidth,
              fontSize: 10,
              align: 'center',
              verticalAlign: 'middle',
              width: 30
            }"
          />
        </v-label>
      </v-layer>
    </v-stage>
    <el-alert
      v-if="terraceWidth"
      type="info"
      show-icon
      :closable="false"
      style="margin-top: 12px"
    >
      <template>
        {{ $tc('terraceAccessoriesMsg', 1, {width: maxGlassWidthInMiliMeters}) }}
      </template>
    </el-alert>
    <div class="GlassThickness">
      <h4>
        {{ $t('terraceGlassThicknessQ') }}
        <el-button
          icon="el-icon-info"
          class="help-icon"
          circle
          @click="startTour(7)"
        />
      </h4>
      <GlassThickness />
      <el-alert
        v-if="recommendedMaxWidthInMm < terraceGlasses[selectedGlass].width"
        style="margin-top: 12px"
        type="info"
        show-icon
        :closable="false"
      >
        {{ $t('terraceGlassThicknessWarning') }}
      </el-alert>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapMultiRowFields, mapFields } from "vuex-map-fields";
import GlassThickness from "@/components/Glasart/Glasstaerke.vue";
import i18n from "@/js/plugins/i18n";
const frame_width = window.innerWidth - 40;
const frame_height = 400;

export default {
  name: "Scheibenanzahl",
  components: {
    GlassThickness
  },
  data() {
    return {
      selectedGlass: 0,
      is80Milimeters: false,
      isAluminium: false,
      stageSize: {
        width: frame_width,
        height: frame_height
      },
      ratio: 1
    };
  },
  computed: {
    ...mapGetters([
      "profileWidthSum",
      "maxGlassWidthInMiliMeters",
      "currentAccumulatedWidthOfGlassinMiliMeters",
      "getAccumulatedWeightOfGlassesInKiloGram"
    ]),
    ...mapMultiRowFields(["terraceGlasses"]),
    ...mapFields([
      "minWidth",
      "maxWidth",
      "minHeight",
      "maxHeight",
      "terraceWidth",
      "individualTerraceGlassSizes",
      "recommendedMaxWidthInMm",
      "barWidthInMm",
      "substructure",
      "configId"
    ])
  },
  watch: {
    terraceWidth(){
      this.calculateWidthOfGlass();
    },
    barWidthInMm(){
      this.calculateWidthOfGlass();
    },
    is80Milimeters(newValue){
      if(newValue){
        this.barWidthInMm = 80;
      } else {
        this.barWidthInMm = 60;
      }
    },
    isAluminium(newValue){
      if(newValue){
        this.substructure = i18n.t('aluminium');
      } else {
        this.substructure = i18n.t('wood');
      }
    },
    individualTerraceGlassSizes(newValue) {
      if(!newValue){
        this.calculateWidthOfGlass();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.configId || this.startTour(0); // TODO: Check if it works
    });
  },
  created() {
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
    this.substructure = i18n.t('wood');
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    startTour(step) {
      const tour = this.$shepherd({
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          },
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          }
        },
        useModalOverlay: true
      });

      tour.addSteps([{
        title: i18n.t('terraceTourWelcome'),
        text: i18n.t('terraceTourGreetingHtml'),
        buttons: [
          {
            action: function() {
              return this.cancel();
            },
            secondary: true,
            text: i18n.t('terraceTourCancel')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('terraceTourStart')
          }
        ]
      },
      {
        attachTo: { element: '.terraceWidth', on: 'bottom' },
        title: i18n.t('terraceTourWidthTitle'),
        text: i18n.t('terraceTourWidthHtml'),
        buttons: [
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.terraceGlassesLength', on: 'bottom' },
        title: i18n.t('terraceTourLengthTitle'),
        text: i18n.t('terraceTourLengthHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.individualTerraceGlassSizes', on: 'bottom' },
        title: i18n.t('terraceTourSizeTitle'),
        text: i18n.t('terraceTourSizeHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.is80Milimeters', on: 'bottom' },
        title: i18n.t('terraceTourGirderStrengthTitle'),
        text: i18n.t('terraceTourGirderStrengthHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.isAluminium', on: 'bottom' },
        title: i18n.t('terraceTourSubstructureTitle'),
        text: i18n.t('terraceTourSubstructureHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.TerraceGlassSizes', on: 'bottom' },
        title: i18n.t('terraceTourSizesTitle'),
        text: i18n.t('terraceTourSizesHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('nextBtn')
          }
        ]
      },
      {
        attachTo: { element: '.GlassThickness', on: 'top' },
        title: i18n.t('terraceTourThicknessTitle'),
        text: i18n.t('terraceTourThicknessHtml'),
        buttons: [
          {
            action: function() {
              return this.back();
            },
            secondary: true,
            text: i18n.t('backBtn')
          },
          {
            action: function() {
              return this.next();
            },
            text: i18n.t('doneBtn')
          }
        ]
      }]);

      tour.show(step);
    },
    adaptGlasses(newValue) {
      if (newValue > this.terraceGlasses.length) {
        this.$store.commit("addTerraceGlass");
      } else {
        this.$store.commit("removeTerraceGlass");
      }
      this.calculateWidthOfGlass();
    },
    updateGlasses(selectedGlass = 0) {
      let generalizedItem = this.terraceGlasses[selectedGlass];
      this.terraceGlasses.forEach((glassItem) => {
        if (!this.individualTerraceGlassSizes) { glassItem.width = generalizedItem.width }
        glassItem.height = generalizedItem.height;
      });
      this.$emit('validate');
      this.updateCanvas();
    },
    calculateXpos(index, isGlass) {
      var push = 0;
      if (index > 0 && index <= this.terraceGlasses.length) {
        var i = isGlass ? index : index - 1;
        push += i * (this.barWidthInMm - this.getProfileDeduction());
      }

      return 20 + (this.barWidthInMm - this.getProfileDeduction() + this.getPreviousWidthOfGlasses(index) + push - (this.getProfileDeduction() * index)) * this.ratio;
    },
    getProfileDeduction() {
      return (this.barWidthInMm - 20) / 2;
    },
    calculateWidthOfGlass(){
      if(!this.individualTerraceGlassSizes && this.terraceWidth){
        this.terraceGlasses[0].width = parseInt(this.maxGlassWidthInMiliMeters / this.terraceGlasses.length);
        this.updateGlasses();
      }
    },
    getPreviousWidthOfGlasses(index) {
      var sum = 0;

      for(var i = 0; i < index; i++){
        sum += parseInt(this.terraceGlasses[i].width);
      }

      return sum;
    },
    updateCanvas() {
      if(this.terraceGlasses.length > 0){
        this.stageSize.height = frame_height;
        var frameWidth = parseInt(this.terraceWidth);
        var frameHeight = parseInt(this.terraceGlasses[0].height);

        var wr = (this.stageSize.width - 45) / frameWidth || 0;
        var hr = (this.stageSize.height - 45) / frameHeight || 0;

        var ratio = Math.min(wr, hr);
        this.ratio = ratio;
        this.stageSize.height = this.terraceGlasses[0].height * ratio + 55;
      }
    },
    resizeCanvas() {
      this.stageSize.width = window.innerWidth - 40;
      this.updateCanvas();
    }
  }
};
</script>
<style lang="scss" scoped>
.delete-icon {
  margin-left: 10px;
  color: red;
  cursor: pointer;
}
.el-button.is-circle.help-icon {
  padding: 0;
  border: none;
}
</style>
