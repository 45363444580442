var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layer',[_c('v-rect',{attrs:{"config":{
      x: _vm.x + (_vm.thickness / 2 * _vm.ratio),
      y: _vm.y + (_vm.thickness / 2 * _vm.ratio),
      width: (_vm.length - (_vm.thickness)) * _vm.ratio,
      height: (_vm.height - (_vm.thickness)) * _vm.ratio,
      stroke: _vm.farbe,
      strokeWidth: _vm.thickness * _vm.ratio,
      shadowColor: 'rgba(0, 0, 0, .1)',
      shadowBlur: 5,
      shadowOffset: { x: 0, y: -5 },
    }}}),_c('div',[_c('v-line',{attrs:{"config":{
        x: _vm.x - 15,
        y: _vm.y,
        points: [5, 0, 0, 0, 0, _vm.height * _vm.ratio, 5, _vm.height * _vm.ratio],
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}),_c('v-label',{attrs:{"config":{
        x: _vm.x - 20,
        y: 20 + (_vm.height * _vm.ratio / 2) + 30
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
          text: `${_vm.inputHeight} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          rotation: 270,
          width: 50
        }}})],1),_c('v-line',{attrs:{"config":{
        x: _vm.x,
        y: (_vm.height * _vm.ratio) + _vm.y + 15,
        points: [ _vm.length * _vm.ratio, -5, _vm.length * _vm.ratio, 0, 0, 0, 0, -5 ],
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}),_c('v-label',{attrs:{"config":{
        x: _vm.x + (_vm.length * _vm.ratio) / 2 - 25,
        y: _vm.height * _vm.ratio + _vm.y + 10
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff' }}}),_c('v-text',{attrs:{"config":{
          text: `${_vm.inputLength} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          width: 50
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }