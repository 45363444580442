<template>
  <div>
    <el-radio-group
      v-model="farbe"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="color in colors"
        :key="getColorTitle(color)"
        :label="color"
        class="card color"
        rules="required"
      >
        <img
          :src="getImgUrl(color)"
          class="image"
        >
        <div class="caption">
          <span class="title">{{ getColorTitle(color) }}</span>
          <div class="bottom clearfix">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>
    <div
      v-if="showNextBtn"
      align="right"
    >
      <el-button
        type="primary"
        :disabled="!farbe"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Farbe",
  props: {
    showNextBtn: {
      type: Boolean,
      default: true,
      description: "Decides if the next-button will be displayed."
    },
    colors: {
      type: Array,
      default: () => (['Chrome', 'Edelstahl', 'Edelstahl Design', 'glanzverchromt', 'Schwarz'])
    },
    nextIndex: {
      type: Number,
      default: 3
    },
    folderPath: {
      type: String,
      default: 'shower/colors/'
    }
  },
  computed: {
    ...mapFields([
      "farbe"
    ])
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getColorTitle(color) {
      return `${i18n.t(`color${this.capitalizeFirstLetter(color.replace(/\s/g, ''))}`)}`;
    },
    getImgUrl(color) {
      const file = color.replace(/\s/g, '-').toLowerCase();
      const images = require.context("../../assets/", true, /\.svg$/);

      return images(`./${this.folderPath}${file}.svg`);
    },
  }
};
</script>