
<template>
  <svg
    class="image fit"
    version="1.1"
    viewBox="0 0 130.18 130.18"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><defs><linearGradient
    id="linearGradient11644"
    x1="301.25"
    x2="314.54"
    y1="447.11"
    y2="447.11"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".06"
    offset="0"
  /><stop
    stop-color="#fff"
    stop-opacity=".03"
    offset=".21029"
  /><stop
    stop-color="#fff"
    stop-opacity=".06"
    offset=".7472"
  /><stop
    stop-color="#fff"
    stop-opacity=".03"
    offset="1"
  /></linearGradient><linearGradient
    id="linearGradient14560"
    x1="315.41"
    x2="326.46"
    y1="447.11"
    y2="447.11"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".3"
    offset="0"
  /><stop
    stop-color="#f5f5f5"
    stop-opacity=".25"
    offset="1"
  /></linearGradient><linearGradient
    id="linearGradient15294"
    x1="327.5"
    x2="335.44"
    y1="447.11"
    y2="447.11"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".15"
    offset="0"
  /><stop
    stop-color="#fff"
    stop-opacity=".2"
    offset=".65213"
  /><stop
    stop-color="#fff"
    stop-opacity=".15"
    offset="1"
  /></linearGradient><linearGradient
    id="linearGradient18934"
    x1="336.41"
    x2="352.41"
    y1="447.11"
    y2="447.11"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".05"
    offset="0"
  /><stop
    stop-color="#fff"
    stop-opacity=".07"
    offset="1"
  /></linearGradient><linearGradient
    id="linearGradient19668"
    x1="353.49"
    x2="366.24"
    y1="447.11"
    y2="447.11"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".15"
    offset="0"
  /><stop
    stop-color="#fff"
    stop-opacity=".1"
    offset="1"
  /></linearGradient><linearGradient
    id="linearGradient21854"
    x1="367.6"
    x2="371.24"
    y1="447.09"
    y2="447.09"
    gradientTransform="matrix(.26458 0 0 .26458 2.569 -4.7412e-7)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".3"
    offset=".85"
  /><stop
    stop-color="#fff"
    stop-opacity=".2"
    offset=".9"
  /></linearGradient><linearGradient
    id="linearGradient166460"
    x1="98.36"
    x2="125.3"
    y1="118.38"
    y2="118.38"
    gradientTransform="translate(2.569)"
    gradientUnits="userSpaceOnUse"
  ><stop
    stop-color="#fff"
    stop-opacity=".18"
    offset=".04"
  /><stop
    stop-opacity=".05"
    offset=".094828"
  /><stop
    stop-color="#fff"
    stop-opacity=".05"
    offset=".12"
  /></linearGradient></defs><g transform="translate(-39.938 -53.192)"><path
    :fill="fill"
    d="m82.261 53.589h45.584v129.6h-45.584z"
    stroke-width=".27966"
  /><path
    d="m100.93 53.567h26.939v129.63h-26.939z"
    fill="url(#linearGradient166460)"
    stroke-width=".43305"
  /><g stroke-width=".054036"><path
    d="m82.473 53.556a0.19966 0.19966 0 0 0-0.19947 0.19947v129.08a0.19966 0.19966 0 0 0 0.19947 0.19947h3.1187a0.19966 0.19966 0 0 0 0.19947-0.19947v-129.08a0.19966 0.19966 0 0 0-0.19947-0.19947h-1.5591z"
    fill="url(#linearGradient11644)"
  /><path
    d="m86.205 53.571a0.18434 0.18434 0 0 0-0.18449 0.18448v129.08a0.18434 0.18434 0 0 0 0.18449 0.18448h2.5559a0.18434 0.18434 0 0 0 0.18449-0.18448v-129.08a0.18434 0.18434 0 0 0-0.18449-0.18448h-1.278z"
    fill="url(#linearGradient14560)"
  /><path
    d="m89.375 53.6a0.1555 0.1555 0 0 0-0.15554 0.15555v129.08a0.1555 0.1555 0 0 0 0.15554 0.15554h1.7896a0.1555 0.1555 0 0 0 0.15555-0.15554v-129.08a0.1555 0.1555 0 0 0-0.15555-0.15555h-0.89452z"
    fill="url(#linearGradient15294)"
  /><path
    d="m91.726 53.607a0.14906 0.14906 0 0 0-0.14883 0.14883v129.08a0.14906 0.14906 0 0 0 0.14883 0.14882h3.9367a0.14906 0.14906 0 0 0 0.14883-0.14882v-129.08a0.14906 0.14906 0 0 0-0.14883-0.14883h-1.9684z"
    fill="url(#linearGradient18934)"
  /><path
    d="m96.225 53.628a0.12782 0.12782 0 0 0-0.12764 0.12764v129.08a0.12782 0.12782 0 0 0 0.12764 0.12764h3.1182a0.12782 0.12782 0 0 0 0.12764-0.12764v-129.08a0.12782 0.12782 0 0 0-0.12764-0.12764h-1.5591z"
    fill="url(#linearGradient19668)"
  /><path
    d="m99.829 53.657c-0.02138 0.0058-0.0013 0.73185 0.0013 2.0509-0.0024 0.76194 0.02019 1.8825 0.01812 3.2613-0.0041 2.7577-0.0076 6.6643-0.01035 11.38-0.0055 9.4317-0.0083 22.101-0.0083 35.287 6e-6 26.373 0.01114 54.818 0.03307 63.587l0.03411 13.607c2.64e-4 0.0752 0.06521 0.1338 0.14004 0.12661l0.28577-0.0284c0.0809-8e-3 0.15696-0.0176 0.21704-0.0274 0.03-5e-3 0.0561-0.01 0.0785-0.015 0.0112-3e-3 0.0214-5e-3 0.0331-9e-3 0.0116-4e-3 0.0214-1e-3 0.0548-0.0305 1.6e-4 -1.4e-4 0.0432-0.0902 0.0434-0.0956 2.4e-4 -5e-3 2.6e-4 -0.0111 5.2e-4 -0.0186 2.7e-4 -0.0149 8e-4 -0.0359 1e-3 -0.0636 5.3e-4 -0.0553 1e-3 -0.13687 2e-3 -0.24443 1e-3 -0.2151 2e-3 -0.5334 4e-3 -0.94877 2e-3 -0.83076 5e-3 -2.0494 7e-3 -3.6024 4e-3 -3.1058 9e-3 -7.5499 0.0124-12.908 7e-3 -10.717 0.0122-25.09 0.015-39.725 6e-3 -29.271 1e-3 -59.588-0.0186-63.804l-0.0362-7.6812c-2.6e-4 -0.07025-0.0574-0.12703-0.12764-0.12712h-0.29248z"
    fill="url(#linearGradient21854)"
  /></g><path
    d="m81.875 118.28v-65.088h46.302v130.18h-46.302zm3.7042 0v-64.558h-3.175v129.12h3.175zm3.175 0v-64.558h-2.6458v129.12h2.6458zm2.3812 0v-64.558h-1.8521v129.12h1.8521zm4.4979 0v-64.558h-3.9688v129.12h3.9688zm3.7042 0v-64.558h-3.175v129.12h3.175zm1.3229 0c0-57.209-0.0408-64.562-0.0408-64.562l-0.77469 0.0013s0.02168 10.929 0.02168 64.561c0 52.23 0.0344 64.554 0.0344 64.554l0.7683-4e-3s-9e-3 -7.3413-9e-3 -64.55zm26.988 0v-64.558h-26.458v129.12h26.458z"
    stroke-width=".26458"
  /></g></svg>
</template>
<script>
  export default {
    name: "HandlingSvg",
    props: {
      fill: {
        type: String,
        default: '#222'
      },
    }
  }
</script>