var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('image-component',{attrs:{"x":_vm.x,"y":_vm.y,"file":_vm.getFilePath(),"align":"center","vertical":"center","scale":_vm.scale}}),(_vm.active)?_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        -10, 0,
        10, 0,
        0, 0,
        0, -10,
        0, 10,
      ],
      closed: false,
      stroke: 'rgba(200, 0, 0, 1)',
      strokeWidth: 1,
    }}}):_vm._e(),(_vm.active)?_c('v-line',{attrs:{"config":{
      x: _vm.length + 15,
      y: _vm.y,
      points: [
        15, 0,
        20, 0,
        20, _vm.realHeight * _vm.scale,
        15, _vm.realHeight * _vm.scale
      ],
      closed: false,
      stroke: 'rgba(200, 0, 0, 1)',
      strokeWidth: 1,
    }}}):_vm._e(),(_vm.active)?_c('v-label',{attrs:{"config":{
      x: _vm.length + 30,
      y: _vm.y + (_vm.realHeight * _vm.scale / 2) + 25
    }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
        text: `${_vm.realHeight} mm`,
        fontSize: 10,
        align: 'center',
        verticalAlign: 'middle',
        rotation: 270,
        width: 50
      }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }