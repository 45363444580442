<template>
  <!-- Drain wall -->
  <v-ellipse
    :config="{
      x: x,
      y: y,
      width: length,
      height: height,
      fill: fill,
      shadowColor: 'rgba(0, 0, 0, .4)',
      shadowBlur: 0,
      shadowOffset: { x: 0, y: 2 },
    }"
  />
</template>
<script>
export default {
  name: "Drain",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    fill: {
      type: String,
      default: 'rgb(170, 170, 170)'
    }
  }
}
</script>