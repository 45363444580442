var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"formatObserver",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('el-row',{staticStyle:{"margin-bottom":"8px"}},[_c('el-radio-group',{attrs:{"fill":"#1bafd6"},model:{value:(_vm.selectedFormat),callback:function ($$v) {_vm.selectedFormat=$$v},expression:"selectedFormat"}},_vm._l((_vm.possibleFormats),function(format){return _c('el-radio-button',{key:format.title,attrs:{"label":format.title}},[_c('img',{staticStyle:{"width":"24px"},attrs:{"src":_vm.getImgUrl(format)}})])}),1)],1),_c('el-row',{staticStyle:{"margin-bottom":"8px"},attrs:{"gutter":12}},[_c('el-col',{attrs:{"xs":12,"sm":5,"md":5,"lg":4,"xl":3}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('width'),"required":true,"rules":{
          required: true,
          numeric: true,
          between: { min: _vm.minWidth, max: _vm.maxWidth }
        }}},[_c('el-input',{model:{value:(_vm.length),callback:function ($$v) {_vm.length=$$v},expression:"length"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1),_c('el-col',{attrs:{"xs":12,"sm":5,"md":5,"lg":4,"xl":3}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('height'),"required":true,"rules":{
          required: true,
          numeric: true,
          between: { min: _vm.minHeight, max: _vm.maxHeight }
        }}},[_c('el-input',{model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1),_c('el-col',{attrs:{"xs":8,"sm":3,"md":3,"lg":2,"xl":2}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('weight'),"rules":{ required: true }}},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("kg")])])],1)],1)],1),(!isNaN(_vm.length) && !isNaN(_vm.height) && !isNaN(_vm.ratio))?_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize}},[_c('v-layer',[_c('v-rect',{attrs:{"config":{
          x: 20,
          y: 20,
          width: _vm.length * _vm.ratio,
          height: _vm.height * _vm.ratio,
          fillLinearGradientStartPoint: { x: (_vm.height * _vm.ratio) / 2, y: 0 },
          fillLinearGradientEndPoint: { x: 20, y: (_vm.length * _vm.ratio) / 2 },
          fillLinearGradientColorStops: [0, '#000', 1, '#222'],
          stroke: '#000',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }}}),_c('v-rect',{attrs:{"config":{
          x: 20 + _vm.frameThickness / 2 * _vm.ratio,
          y: 20 + _vm.frameThickness / 2 * _vm.ratio,
          width: (_vm.length - _vm.frameThickness) * _vm.ratio,
          height: (_vm.height - _vm.frameThickness) * _vm.ratio,
          fillLinearGradientStartPoint: { x: ((_vm.height - _vm.frameThickness) * _vm.ratio) / 2, y: 0 },
          fillLinearGradientEndPoint: { x: 20, y: ((_vm.length - _vm.frameThickness) * _vm.ratio) / 2 },
          fillLinearGradientColorStops: [0, '#fcfcfc', 1, '#ddd'],
          stroke: '#000',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }}})],1),_c('v-layer',[_c('v-line',{attrs:{"config":{
          x: 5,
          y: 20,
          points: [10, 0, 0, 0, 0, _vm.height * _vm.ratio, 10, _vm.height * _vm.ratio],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }}}),_c('v-line',{attrs:{"config":{
          x: 20,
          y: _vm.height * _vm.ratio + 20 + 15,
          points: [_vm.length * _vm.ratio, -10, _vm.length * _vm.ratio, 0, 0, 0, 0, -10],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }}})],1),_c('v-layer',[_c('v-label',{attrs:{"config":{
          x: 1,
          y: 20 + (_vm.height / 2) * _vm.ratio + 15
        }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
            text: _vm.height,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            rotation: 270,
            width: 30
          }}})],1),_c('v-label',{attrs:{"config":{
          x: 20 + (_vm.length * _vm.ratio) / 2 - 15,
          y: _vm.height * _vm.ratio + 20 + 10
        }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff' }}}),_c('v-text',{attrs:{"config":{
            text: _vm.length,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            width: 30
          }}})],1)],1)],1):_vm._e(),_c('GlassThickness',{staticStyle:{"margin-top":"12px"}}),_c('div',{attrs:{"align":"right"}},[_c('el-button',{attrs:{"type":"primary","disabled":invalid},on:{"click":function($event){return _vm.$emit('nextStep', 1)}}},[_vm._v(" "+_vm._s(_vm.$t('nextBtn'))+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }