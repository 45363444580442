<template>
  <div>
    <el-radio-group
      v-model="selectedFrame"
      fill="#1bafd6"
    >
      <el-radio-button
        v-for="frame in frames"
        :key="getFrameTitle(frame)"
        :label="getFrameTitle(frame)"
        class="card"
        rules="required"
      >
        <img
          :src="getImgUrl(frame)"
          class="image"
        >
        <div class="caption">
          <span class="title">{{ getFrameTitle(frame) }}</span>
          <div class="bottom clearfix">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>
    <div
      v-if="showNextBtn"
      align="right"
    >
      <el-button
        type="primary"
        :disabled="!selectedFrame"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";
export default {
  name: "Rahmen",
  props: {
    showNextBtn: {
      type: Boolean,
      default: true,
      description: "Decides if the next-button will be displayed."
    }
  },
  computed: {
    ...mapFields([
      "selectedFrame"
      ,"frames"
    ])
  },
  methods: {
    next() {
      this.$emit("nextStep", 3);
    },
    getFrameTitle(frame) {
      return `${i18n.t(`frame${frame.name.replace(/\s/g, '')}`)} ${frame.size}`;
    },
    getImgUrl(frame) {
      var file = frame.name.replace(/\s/g, '-').toLowerCase();
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./frame-" + file + ".jpg");
    }
  }
};
</script>