import Vue from 'vue';

function clamp(num) {
  return Math.min(Math.max(num, 0), 255)
}
function getColorObject(color) {
  if (color.length > 9) {
    color = toRGB(color);
  } else {
    color = hexToRGB(color);
  }

  return color;
}
function hexToRGB(hex) {
  const i = parseInt;
  let x = {}, n = hex.length;

  if (n==8||n==6||n<4) return null;
  if (n<6) {
    hex = "#"+hex[1]+hex[1]+hex[2]+hex[2]+hex[3]+hex[3]+(n>4?hex[4]+hex[4]:"");
  }

  hex = i(hex.slice(1), 16);
  if(n==9||n==5) {
    x.r = hex >> 24 & 255;
    x.g = hex >> 16 & 255;
    x.b = hex >> 8 & 255;
    x.a = Math.round((hex & 255) / 0.255) / 1000;
  } else {
    x.r = hex >> 16;
    x.g = hex >> 8 & 255;
    x.b = hex & 255;
    x.a = -1;
  } 

  return x;
}
function toRGB(color) {
  const i = parseInt;
  let x = {};

  const [t,g,b,a] = color.split(",");
  const r = (t[3] == "a" ? t.slice(5) : t.slice(4));
  x.r = i(r);
  x.g = i(g);
  x.b = i(b);
  x.a = a ? parseFloat(a) : -1;

  return x;
}

Vue.mixin({
  methods: {
    rgbLighter: (color, value) => {
      if (!color) return;
      const c = clamp;
      color = getColorObject(color);

      return "rgb"+(color.a?"a(":"(") +
        c(color.r + value) + "," +
        c(color.g + value) + "," +
        c(color.b + value) +
        (color.a > -1 ? ","+color.a+")" : ")");
    },
    isLight: color => {
      if (!color) return;
      color = getColorObject(color);

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(
        0.299 * (color.r * color.r) +
        0.587 * (color.g * color.g) +
        0.114 * (color.b * color.b)
      );

      // Using the HSP value, determine whether the color is light or dark
      if (hsp>140) {
        return true;
      } else {
        return false;
      }
    }
  }
});
