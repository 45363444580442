<template>
  <v-group 
    ref="node" 
    :config="{ draggable: false }"
  >
    <v-rect
      :config="{
        x: x,
        y: y,
        width: length,
        height: height,
        fillLinearGradientStartPoint: { x: (height / 2), y: 0 },
        fillLinearGradientEndPoint: { x: 0, y: (length / 2) },
        fillLinearGradientColorStops: [
          0, lighterColor,
          0.13, glassColor,
          0.37, lighterColor,
          0.57, lighterColor,
          0.79, glassColor,
          1, glassColor
        ],
      }"
    />

    <v-rect
      :config="{
        x: x,
        y: y + (height - bottomFrame),
        width: length,
        height: bottomFrame,
        fill: frameColor,
        shadowColor: 'rgba(120, 120, 120, .8)',
        shadowBlur: 3,
        shadowOffset: { x: shadowOffset, y: 0 },
      }"
    />
  </v-group>
</template>
<script>
export default {
  name: "Panel",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    glassColor: {
      type: String,
      default: "rgba(152, 165, 186, .5)"
    },
    frameColor: {
      type: String,
      default: "#222"
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    bottomFrame: {
      type: Number,
      default: 0
    },
    shadowOffset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    lighterColor() {
      return this.rgbLighter(this.glassColor, 80);
    },
  },
  methods: {
    getNode() {
      return this.$refs.node.getNode();
    },
  }
}
</script>