<template>
  <div>
    <template v-if="individualTerraceGlassSizes">
      <el-row
        v-for="(glass, index) in terraceGlasses"
        :key="index"
        type="flex"
        align="middle"
        :gutter="10"
      >
        <el-col
          :xs="5"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="1"
        >
          <el-avatar
            shape="square"
            :size="50"
            :src="getImgUrl('glass')"
          />
        </el-col>
        <el-col
          :xs="2"
          :sm="1"
          :md="1"
          :lg="1"
          :xl="1"
        >
          1
        </el-col>
        <el-col
          :xs="17"
          :sm="19"
          :md="20"
          :lg="21"
          :xl="22"
        >
          {{ $tc('pane', 1) }} {{ glass.width }} mm x {{ glass.height }} mm
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <el-col
          :xs="5"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="1"
        >
          <el-avatar
            shape="square"
            :size="50"
            :src="getImgUrl('glass')"
          />
        </el-col>
        <el-col
          :xs="2"
          :sm="1"
          :md="1"
          :lg="1"
          :xl="1"
        >
          {{ terraceGlasses.length }}
        </el-col>
        <el-col
          :xs="17"
          :sm="19"
          :md="20"
          :lg="21"
          :xl="22"
        >
          {{ $tc('pane', terraceGlasses.length > 1 ? 2 : 1) }} {{ terraceGlasses[0].width }} mm x {{ terraceGlasses[0].height }} mm
        </el-col>
      </el-row>
    </template>

    <el-row
      :gutter="10"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="5"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <el-avatar
          shape="square"
          :size="50"
          :src="getImgUrl('placeholder')"
        />
      </el-col>
      <el-col
        :xs="2"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      >
        {{ onlyFrontEdge ? $t('edgesTreatmentActiveText') : $t('edgesTreatmentInactiveText') }}
      </el-col>
      <el-col
        :xs="17"
        :sm="19"
        :md="20"
        :lg="21"
        :xl="22"
      >
        {{ $t('edges') }} {{ selectedEdge }}
      </el-col>
    </el-row>

    <el-row
      v-if="terraceGlasses.length - 1"
      :gutter="10"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="5"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <el-avatar
          shape="square"
          :size="50"
          :src="getImgUrl('mittelprofil')"
        />
      </el-col>
      <el-col
        :xs="2"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      >
        {{ terraceGlasses.length - 1 }}
      </el-col>
      <el-col
        :xs="17"
        :sm="19"
        :md="20"
        :lg="21"
        :xl="22"
      >
        {{ $t('middleProfileText') }} ({{ terraceGlasses[0].height }} mm)
      </el-col>
    </el-row>

    <el-row
      :gutter="10"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="5"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <el-avatar
          shape="square"
          :size="50"
          :src="getImgUrl('endprofil')"
        />
      </el-col>
      <el-col
        :xs="2"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      >
        2
      </el-col>
      <el-col
        :xs="17"
        :sm="19"
        :md="20"
        :lg="21"
        :xl="22"
      >
        {{ $t('endProfileText') }} ({{ terraceGlasses[0].height }} mm)
      </el-col>
    </el-row>

    <el-row
      v-if="selectedKlemmdeckel"
      :gutter="10"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="5"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <el-avatar
          shape="square"
          :size="50"
          :src="getImgUrl(`${selectedKlemmdeckel.type}-${selectedKlemmdeckel.color}`)"
        />
      </el-col>
      <el-col
        :xs="2"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      >
        {{ terraceGlasses.length + 1 }}
      </el-col>
      <el-col
        :xs="17"
        :sm="19"
        :md="20"
        :lg="21"
        :xl="22"
      >
        {{ selectedKlemmdeckel.title }}
      </el-col>
    </el-row>

    <el-row
      v-if="selectedHaltewinkel"
      :gutter="10"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="5"
        :sm="3"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <el-avatar
          shape="square"
          :size="50"
          :src="getImgUrl(`${selectedHaltewinkel.type}-${selectedHaltewinkel.color}`)"
        />
      </el-col>
      <el-col
        :xs="2"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
      >
        {{ terraceGlasses.length + 1 }}
      </el-col>
      <el-col
        :xs="17"
        :sm="19"
        :md="20"
        :lg="21"
        :xl="22"
      >
        {{ $t('bracket') }}
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
export default {
  name: "TerassenBersicht",
  data() {
      return {
          klemmdeckelSelected: false,
          selectedKlemmdeckelSilver: false
      };
  },
  computed: {
    ...mapMultiRowFields(["terraceGlasses"]),
    ...mapFields([
      "selectedHaltewinkel",
      "selectedKlemmdeckel",
      "individualTerraceGlassSizes",
      "selectedEdge",
      "onlyFrontEdge"
    ])
  },
  methods: {
    getImgUrl(filename) {
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + filename + ".jpg");
    }
  }
};
</script>