<template>
  <validation-observer
    ref="uploadObserver"
    v-slot="{ invalid }"
  >
    <div>
      <el-row
        v-for="(drilling, index) in drillings"
        :key="index"
        :gutter="12"
      >
        <el-col>
          <h3>
            {{ $t('drillingsTitle') }}
            <i
              class="el-icon-delete delete-icon"
              @click="removeDrilling(index)"
            />
          </h3>
        </el-col>
        <el-col :span="8">
          <form-item-wrapper
            :label="$t('diameter')"
            :rules="{
              required: true,
              numeric: true,
              inside_values_or_gt: { diameters: getDiameters, gt: diameter_gt }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-edit" />
              <span class="drilling_text">{{ $t('diameter') }}</span>
            </span>
            <el-popover 
              placement="right"
              trigger="hover"
            >
              <div class="extended-info">
                {{ $t('diameterInfo') }}
                <el-table 
                  :data="getTableDiameter" 
                  height="200"
                >
                  <el-table-column 
                    property="diameter" 
                    :label="$t('diameter')" 
                  />
                </el-table>
              </div>
              <i
                slot="reference"
                class="el-icon-info info-icon"
              />
            </el-popover>
            <el-select
              v-model="drilling.durchmesser"
              filterable
              allow-create
              clearable
              :placeholder="$t('diameterPlaceholder')"
            >
              <el-option
                v-for="diameter in getDiameters"
                :key="diameter"
                :label="diameter"
                :value="diameter" 
              />
            </el-select>
          </form-item-wrapper>
        </el-col>
        <el-col :span="8">
          <form-item-wrapper
            :label="$t('distanceLeft')"
            :rules="{
              required: true,
              numeric: true,
              point_in_form: { form: getFormCoordinates(drilling.durchmesser), x: drilling.x, y: height - drilling.y }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-right" />
              <span class="drilling_text">{{ $t('distanceLeft') }}</span>
            </span>
            <el-input v-model="drilling.x">
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col :span="8">
          <form-item-wrapper
            :label="$t('distanceTop')"
            :rules="{
              required: true,
              numeric: true,
              point_in_form: { form: getFormCoordinates(drilling.durchmesser), x: drilling.x, y: height - drilling.y }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-bottom" />
              <span class="drilling_text">{{ $t('distanceTop') }}</span>
            </span>
            <el-input v-model="drilling.y">
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
      </el-row>
      <el-row 
        v-if="drillings.length === 0 && uploadData.length === 0"
        :gutter="12"
      >
        <el-col>
          <el-button
            type="primary"
            :disabled="invalid"
            size="small"
            @click="$emit('nextStep', 1)"
          >
            {{ $t('drillingSkipBtn') }}
          </el-button>
        </el-col>
      </el-row>
      <el-row 
        v-if="drillings.length === 0 && uploadData.length === 0"
        :gutter="12"
      >
        <el-col>
          <p>{{ $t('or') }}</p>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            class="mt-0"
            @click="addDrilling()"
          >
            {{ $t('drillingAddBtn') }}
          </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col>
          <p>{{ $t('or') }}</p>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col class="col-upload-sketch">
          <el-upload
            ref="upload"
            class="upload-sketch"
            accept="application/pdf"
            action=""
            :before-upload="beforeSketchUpload"
            :limit="1"
            :on-remove="handleRemove"
            :file-list="uploadData"
            :http-request="upload"
          >
            <el-button
              :disabled="uploadData.length == 1"
              icon="el-icon-document-add"
              size="small"
              type="primary"
            >
              {{ $t('uploadSketch') }}
            </el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            >
              {{ $t('uploadPdfCondition') }}
            </div>
          </el-upload>
        </el-col>
        <el-col
          v-if="uploadData.length == 1"
          :md="12"
        >
          <form-item-wrapper
            :label="$t('quantity')"
            :rules="{
              numeric: true,
              between: {
                min: 1,
                max: 15
              }
            }"
            use-slot
          >
            <span slot="label">
              <span>{{ $t('drillingsSketchMsg') }}</span>
            </span>
            <el-input-number
              v-model="drillingsCounter"
              size="small"
              :min="0"
              :max="15"
            />
          </form-item-wrapper>
        </el-col>
      </el-row>
      <div align="right">
        <el-button
          type="primary"
          :disabled="invalid"
          @click="$emit('nextStep', 1)"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapMultiRowFields, mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Bohrungen",
  data() {
    return {
      diameter_gt: 72,
      diameters: [6,8,10,12,14,15,16,18,20,22,24,25,26,28,30,32,35,36,38,40,45,50,55,60,65,68,70]
    }
  },
  computed: {
    ...mapMultiRowFields(["drillings"]),
    ...mapFields(["glassKind", "glassThickness", "length", "height", "drillingsCounter", "uploadData", "configId"]),
    getDiameters() {
      let diameters = this.diameters;
      for( var i = 0; i < diameters.length; i++){
        if (this.glassKind == "esg" && diameters[i] < this.glassThickness) {
          diameters.splice(0, 1);
          i--;
        } else {
          break;
        }
      }
      return diameters;
    },
    getTableDiameter() {
      let gridData = [];
      let diameters = this.getDiameters
      for( var i = 0; i < diameters.length; i++){
        gridData.push({diameter: diameters[i]});
      }
      return gridData;
    }
  },
  methods: {
    addDrilling() {
      this.$store.commit("addDrilling");
    },
    getCoordY(durchmesser){
      return this.height - this.$store.getters['getMinDrillingDistance'](durchmesser / 2);
    },
    getFormCoordinates(durchmesser){
      return this.$store.getters['getFormCoordinates'](durchmesser / 2);
    },
    removeDrilling(index) {
      this.$store.commit("removeDrilling", index);
    },
    async handleRemove() {
      this.uploadData = [];
    },
    beforeSketchUpload(file) {
      const isPDF = file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2.5;

      if (!isPDF) {
        this.$message({
          showClose: true,
          message: i18n.t('uploadFileTypeErrorMsg'),
          type: "error",
          duration: 5000
        });
      }
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: i18n.t('uploadFileSizeErrorMsg'),
          type: "error",
          duration: 5000
        });
      }
      return isPDF && isLt2M;
    },
    upload() {
      const formData = new FormData();
      const file = this.$refs.upload.uploadFiles[0];
      const headerConfig = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      formData.append("file", file.raw);
      this.$http
        .post(
          `${process.env.VUE_APP_UPLOAD_FILE}${this.configId}`,
          formData,
          headerConfig
        )
        .then(res => {
          this.uploadData = [
            {
              name: res.data.filename,
              url: process.env.VUE_APP_UPLOAD_PATH + res.data.filename
            }
          ];
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-icon {
  margin-left: 10px;
  color: red;
  cursor: pointer;
}
.mt-0 {
  margin-top: 0 !important;
}
</style>
