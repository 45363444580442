<template>
  <v-image
    v-if="image"
    ref="image"
    :config="{
      x: x,
      y: y,
      image: image,
      scale: { x: (flip ? -1 : 1) * scale, y: 1 * scale },
      offset: offset,
    }"
  />
</template>
<script>
export default {
  name: "ImageComponent",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    file: {
      type: String,
      default: ''
    },
    flip: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left',
      validator(value) {
        const acceptedValues = ['center', 'left', 'right'];
        return acceptedValues.includes(value);
      },
    },
    vertical: {
      type: String,
      default: 'top',
      validator(value) {
        const acceptedValues = ['bottom', 'center', 'top'];
        return acceptedValues.includes(value);
      },
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      image: new Image(1, 1),
    }
  },
  computed: {
    offset() {
      let offset = { x: 0, y: 0 };

      if (this.image && this.align === 'center') {
        offset.x = this.image.width / 2;
      } else if (this.image && this.align === 'right') {
        offset.x = this.image.width;
      }

      if (this.image && this.vertical === 'center') {
        offset.y = this.image.height / 2;
      } else if (this.image && this.vertical === 'bottom') {
        offset.y = this.image.height;
      }

      return offset;
    }
  },
  watch: {
    file() {
      this.loadImg();
    }
  },
  created() {
    this.loadImg();
  },
  methods: {
    loadImg() {
      const image = new window.Image();
      
      image.onload = () => {
        this.image = image;
      };

      const images = require.context("../../../../assets/shower/", true, /\.svg$/);
      image.src = images(this.file);
    },
  }
}
</script>