<template>
  <validation-observer
    ref="hingeObserver"
    v-slot="{ valid }"
  >
    <div>
      <el-row
        :gutter="12"
      >
        <el-col
          v-for="(h, index) in hingePositions"
          :key="index"
          :xs="12"
          :sm="6"
          :md="5"
          :lg="4"
          :xl="3"
        >
          <form-item-wrapper
            label="Abstand"
            :rules="{
              required: true,
              between: { min: (height / hingePositions.length * index) + 30, max: height / (hingePositions.length - index) - 30 }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-bottom" />
              {{ $t('distance') }}
            </span>
            <div v-if="index % 2">
              <el-popover
                ref="popover"
                placement="bottom-start"
                width="150"
                trigger="focus"
              >
                <span>{{ $t('assistance') }}:<br><i class="el-icon-top" /> {{ $t('distance') }} {{ height - h.y }} mm</span>
              </el-popover>
              <el-input
                v-model="h.y"
                v-popover:popover
                :name="`hinge_${index}`"
                @focus="focusInput"
                @blur="blurInput"
              >
                <span slot="suffix">mm</span>
              </el-input>
            </div>
            <div v-else>
              <el-input
                v-model="h.y"
                :name="`hinge_${index}`"
                @focus="focusInput"
                @blur="blurInput"
              >
                <span slot="suffix">mm</span>
              </el-input>
            </div>
          </form-item-wrapper>
        </el-col>
      </el-row>
      <el-radio-group
        v-model="selectedHinge"
        fill="#1bafd6"
        style="margin-bottom: 8px"
        @change="onChange"
      >
        <el-radio-button
          v-for="hinge in hinges"
          :key="hinge.id"
          :label="getLimitedHinge(hinge)"
          :disabled="loadReached(hinge)"
          class="card"
          rules="required"
        >
          <el-button
            type="text"
            class="info-box"
            @click="openInfoBox(hinge.title, hinge.body_html)"
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <img
            :src="hinge.image?.src"
            class="image fit"
          >
          <div class="caption">
            <span class="title">{{ getTitle(hinge.title) }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ (hinge.variants[0].price * 2).toFixed(2) }} €</span>
            </div>
          </div>
          <el-alert
            v-if="loadReached(hinge)"
            style="position:absolute;top:0;padding:8px 10px;border-radius:unset;"
            class="message"
            type="info"
            :title="$t('hinge_maxload_msg', {kg: calculateMaxLoad(hinge)})"
            show-icon
            :closable="false"
          />
          <div class="overlay" />
        </el-radio-button>
      </el-radio-group>
      <div
        align="right"
      >
        <el-button
          type="primary"
          :disabled="!valid || !selectedHinge || selectedHinge.color.toLowerCase() != farbe.toLowerCase() || loadReached(selectedHinge)"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Scharnier",
  props: {
    connection: {
      type: String,
      default: 'Glas-Wand'
    },
    nextIndex: {
      type: Number,
      default: 3
    }
  },
  computed: {
    ...mapGetters([
      "calculateWeight",
      "getDoorCount"
    ]),
    ...mapFields([
      "height",
      "selectedHinge",
      "selectedSideGasket",
      "farbe",
      "hingePositions"
    ]),
    hinges() {
      return this.$store.getters.filterAccessoriesBy([
        ['color', this.farbe],
        ['type', 'Scharnier'],
        ['connection', this.connection]
      ]);
    }
  },
  watch: {
    hinges() {
      const hinges = this.hinges;
      // auto select if only one button
      if (hinges.length === 1) {
        this.selectedHinge = this.getLimitedHinge(hinges[0]);
      }
    },
    height() {
      for (let i = 0; i < this.hingePositions.length; i += 2) {
        this.hingePositions[i + 1].y = this.height - this.hingePositions[i].y;
      }
    }
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    blurInput() {
      this.$emit("activeInput", '');
    },
    calculateMaxLoad(hinge) {
      return hinge.max_load * this.hingePositions.length;
    },
    focusInput(evt) {
      evt.target.select();
      this.$emit("activeInput", evt.target.name);
    },
    getLimitedHinge(hinge) {
      const copy = Object.assign({}, hinge);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      return copy;
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `hinge_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('hinge_', '');
      });

      return segments.join(' ');
    },
    loadReached(hinge) {
      return hinge.max_load != 0 && this.calculateMaxLoad(hinge) < (this.calculateWeight / this.getDoorCount);
    },
    onChange() {
      // reset gasket
      this.selectedSideGasket = "";
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>