<template>
  <!--
    S T A R T   C A B I N
    -------------------------------------------------------------------------
  -->
  <v-group>
    <Floor 
      :x="x"
      :y="y + (height * ratio)"
      :length="(length + (wall.thickness * 2)) * ratio"
      :dimensision="(wall.thickness + wall.dimensision) * ratio"
      :thickness="floor.thickness * ratio"
    />

    <!-- Back wall -->
    <Wall
      :x="x + ((wall.thickness + wall.dimensision) * ratio)"
      :y="y + (wall.dimensision * ratio)"
      :length="(length - (wall.dimensision * 2)) * ratio"
      :height="(height - (wall.dimensision * 2)) * ratio"
      :dimensision="wall.dimensision * ratio"
    />
    
    <!-- Left wall -->
    <Wall
      :pos="'side'"
      :x="x + wall.thickness * ratio"
      :y="y"
      :height="height * ratio"
      :dimensision="wall.dimensision * ratio"
      :thickness="-wall.thickness * ratio"
    />

    <!-- Right wall -->
    <Wall
      :pos="'side'"
      :x="x + ((length + wall.thickness) * ratio)"
      :y="y"
      :height="height * ratio"
      :dimensision="-wall.dimensision * ratio"
      :thickness="wall.thickness * ratio"
    />

    <Drain
      :x="x + (((length / 2) + wall.thickness) * ratio)"
      :y="y + ((height - (wall.dimensision / 2)) * ratio)"
      :length="75 * ratio"
      :height="50 * ratio"
    />

    <ShowerSystem
      v-if="length && length >= 600 && height && height >= 1400"
      :x="x + (((length / 2) + wall.thickness) * ratio)"
      :y="y + getShowerPosY()"
      :length="length"
      :height="height"
      :scale="ratio"
      :material="farbe"
    />

    <v-group>
      <v-line
        :config="{
          x: x - 35,
          y: y,
          points: [5, 0, 0, 0, 0, height * ratio, 5, height * ratio],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x - 40,
          y: 20 + (height * ratio / 2) + 25
        }"
      >
        <v-tag :config="{ fill: '#fff', rotation: 270 }" />
        <v-text
          :config="{
            text: `${inputHeight} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            rotation: 270,
            width: 50
          }"
        />
      </v-label>

      <v-line
        :config="{
          x: x + (wall.thickness * ratio),
          y: (height * ratio) + y + 40,
          points: [ length * ratio, -5, length * ratio, 0, 0, 0, 0, -5 ],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x + ((length + wall.thickness + wall.thickness) * ratio) / 2 - 25,
          y: height * ratio + y + 35
        }"
      >
        <v-tag :config="{ fill: '#fff' }" />
        <v-text
          :config="{
            text: `${inputLength} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            width: 50
          }"
        />
      </v-label>
    </v-group>
  </v-group>
  <!--
    -------------------------------------------------------------------------
    C A B I N   E N D
  -->
</template>
<script>
import Floor from "./Parts/Floor.vue";
import Wall from "./Parts/Wall.vue";
import Drain from './Parts/Drain.vue';
import ShowerSystem from './Parts/ShowerSystem.vue';


export default {
  name: "Cabin",
  components: {
    Floor,
    Wall,
    Drain,
    ShowerSystem,
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    inputLength: {
      type: Number,
      default: 0
    },
    inputHeight: {
      type: Number,
      default: 0
    },
    wall: {
      type: Object,
      default: () => ({
        thickness: 40,
        dimensision: 100
      })
    },
    ratio: {
      type: Number,
      default: 1
    },
    farbe: {
      type: String,
      default: "chrome"
    }
  },
  data() {
    return {
      floor: {
        thickness: 15,
      },
    };
  },
  methods: {
    getShowerPosY(){
      let posY = (this.height - 2000) * this.ratio;
      if (posY < 0) {
        posY = -this.y
      }
      return posY;
    }
  }
}
</script>
