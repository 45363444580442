<template>
  <div style="position: relative;">
    <v-stage
      ref="stage"
      :config="stageSize"
    >
      <Frame
        v-if="showStage()"
        :x="x + offset.x"
        :y="y + offset.y"
        :length="length"
        :height="height"
        :ratio="ratio"
        :farbe="selectedHex"
        :input-length="length"
        :input-height="height"
        :thickness="frameWidth"
      />
      
      <!--
        -------------------------------------------------------------------------
        W A L L   S T A R T
      -->
      <v-layer v-if="showStage()">
        <v-group 
          v-for="(lane, index) in lanes" 
          :key="lane"
        >
          <v-group v-if="fitting !== 'right'">
            <PanelTopDown
              :active="index === 0"
              :x="getPanelPos(index, lanes - 1, 'left')"
              :y="y + offset.y + (frameWidth * ratio) + (1.5 * index)"
              :length="getPanelLength() * ratio"
              :height="1.5"
            />
            <ButtonTopDown
              v-if="(!lock || fitting === 'left') && index === lanes - 1"
              :x="getPanelPos(lanes - 1, lanes - 1, 'left') + ((getPanelLength() - 22) * ratio - 4)"
              :y="y + offset.y + (frameWidth * ratio) + (1 * index)"
              :length="3"
              :height="3 * lanes"
              :color="selectedHex"
            />
            <ButtonTopDown
              v-if="lock && fitting !== 'left' && index === lanes - 1"
              :x="getPanelPos(lanes - 1, lanes - 1, 'left') + (getPanelLength() * ratio - 10)"
              :y="y + offset.y + (frameWidth * ratio) + (1 * index)"
              :length="10"
              :height="2"
              :color="selectedHex"
            />

            <Panel
              :ref="index === lanes - 1 ? 'panelLeft' : ''"
              :x="getPanelPos(index, lanes - 1, 'left')"
              :y="y + offset.y + (frameWidth * ratio)"
              :length="getPanelLength() * ratio"
              :height="(height - (frameWidth * 2)) * ratio"
              :bottom-frame="63 * ratio"
              :shadow-offset="index === 0 ? 0 : -3"
              :frame-color="selectedHex"
              :glass-color="glassColor"
            />
            <Button
              v-if="(!lock || fitting !== 'right') && index === lanes - 1"
              :x="getPanelPos(lanes - 1, lanes - 1, 'left') + ((getPanelLength() + 20 - 70) * ratio)"
              :y="y + offset.y + ((height - 1050 - 10) * ratio)"
              :radius="20 * ratio"
              :color="selectedHex"
            />
            <Lock 
              v-if="lock && fitting !== 'left' && index === lanes - 1"
              :x="getPanelPos(lanes - 1, lanes - 1, 'left') + ((getPanelLength() - 100) * ratio)"
              :y="y + offset.y + ((height - 1050 - 50) * ratio)"
              :length="100 * ratio"
              :height="130 * ratio"
              :color="selectedHex"
              :ratio="ratio"
            />
            <Arrow
              v-if="index === lanes - 1"
              :x="getPanelPos(lanes - 1, lanes - 1, 'left') + ((getPanelLength() + 150) * ratio)"
              :y="y + offset.y + (height / 2 * ratio)"
              :ratio="ratio"
            />
          </v-group>
          <v-group v-if="fitting !== 'left'">
            <PanelTopDown
              :active="index === 0"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right')"
              :y="y + offset.y + (frameWidth * ratio) + (1.5 * index)"
              :length="getPanelLength() * ratio"
              :height="1.5"
            />
            <ButtonTopDown
              v-if="(!lock || fitting === 'right') && index === lanes - 1"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right') + (22 * ratio) + 2"
              :y="y + offset.y + (frameWidth * ratio) + (1 * index)"
              :length="3"
              :height="3 * lanes"
              :color="selectedHex"
            />
            <ButtonTopDown
              v-if="lock && fitting !== 'left' && index === lanes - 1"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right')"
              :y="y + offset.y + (frameWidth * ratio) + (1 * index)"
              :length="10"
              :height="2"
              :color="selectedHex"
            />
            <Panel
              :ref="index === lanes - 1 ? 'panelRight' : ''"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right')"
              :y="y + offset.y + (frameWidth * ratio)"
              :length="getPanelLength() * ratio"
              :height="(height - (frameWidth * 2)) * ratio"
              :bottom-frame="63 * ratio"
              :shadow-offset="index === 0 ? 0 : 3"
              :frame-color="selectedHex"
              :glass-color="glassColor"
            />
            <Button
              v-if="(!lock || fitting !== 'left') && index === lanes - 1"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right') + ((70 - 20) * ratio)"
              :y="y + offset.y + ((height - 1050 - 10) * ratio)"
              :radius="20 * ratio"
              :color="selectedHex"
            />
            <Lock 
              v-if="lock && fitting !== 'right' && index === lanes - 1"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right')"
              :y="y + offset.y + ((height - 1050 - 50) * ratio)"
              :length="100 * ratio"
              :height="130 * ratio"
              :color="selectedHex"
              :ratio="ratio"
            />
            <Arrow
              v-if="index === lanes - 1"
              :x="getPanelPos((lanes * getPanelMultiplier - 1) - index, lanes * getPanelMultiplier - lanes, 'right') - (150 * ratio)"
              :y="y + offset.y + (height / 2) * ratio"
              :ratio="ratio"
              :rotate="180"
            />
          </v-group>
        </v-group>
      </v-layer>
      <!--
        -------------------------------------------------------------------------
        W A L L   E N D
      -->
    </v-stage>
    <div class="view-help">
      <div>
        <p><small>außen</small></p>
        <i class="el-icon-top" /><br>
        <i class="el-icon-view" />
        <p><small>innen</small></p>
      </div>
    </div>
  </div>
</template>
<script>
import Frame from './Parts/Frame.vue';
import Panel from './Parts/Panel.vue';
import PanelTopDown from './Parts/PanelTopDown.vue';
import Lock from './Parts/Lock.vue';
import ButtonTopDown from './Parts/ButtonTopDown.vue';
import Button from './Parts/Button.vue';
import Arrow from './Parts/Arrow.vue';
import { mapGetters } from "vuex";
import { mapMultiRowFields, mapFields } from "vuex-map-fields";
// import Konva from "konva";

export default {
  name: "SlidingWall",
  components: {
    Frame,
    Panel,
    PanelTopDown,
    Lock,
    Button,
    ButtonTopDown,
    Arrow
},
  data() {
    return {
      timeout: null,
      x: 0,
      y: 0,
      frameWidth: 30,
      overlapping: 30,
      offset: {
        x: 30,
        y: 30
      },
      stageSize: {
        width: 0,
        height: 0,
      },
      ratio: 1,
      weight: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getMaxWidth",
      "getPanelMultiplier"
    ]),
    ...mapMultiRowFields(["drillings"]),
    ...mapFields([
      "selectedHex",
      "fitting",
      "glassColor",
      "minWidth",
      "minHeight",
      "maxHeight",
      "lanes",
      "length",
      "lock",
      "height",
    ]),
    maxWidth() {
      return this.getMaxWidth * this.lanes * this.getPanelMultiplier;
    }
  },
  watch: {
    length() {
      this.lazyCaller(this.updateCanvas());
    },
    height() {
      this.lazyCaller(this.updateCanvas());
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeCanvas();
    });
  },
  created() {
    window.addEventListener("resize", this.resizeCanvas);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    animate() {
      // const amplitude = 250 * this.ratio;
      // const period = 5000;
      // in ms
      // const centerX = this.$refs.stage.getNode().getWidth() / 2;
      const panelLeft = this.$refs.hasOwnProperty('panelLeft') ? this.$refs.panelLeft[0].getNode() : null;
      const panelRight = this.$refs.hasOwnProperty('panelRight') ? this.$refs.panelRight[0].getNode() : null;
      // const X = panelLeft?.getX();

      panelLeft?.to({ x: (this.getPanelLength() / 1.5) * this.ratio, duration: 0.9 });
      panelRight?.to({ x: (-this.getPanelLength() / 1.5) * this.ratio, duration: 0.9 });

      // const anim = new Konva.Animation(function(frame) {
      //   panel.setX(
      //     amplitude * Math.sin((frame.time * 2 * Math.PI) / period) + X
      //   );
      // }, panel.getLayer());

      // anim.start();
    },
    lazyCaller(callback, time = 150) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(callback, time)
    },
    showStage() {
      const valid = this.length && this.minWidth && this.maxWidth &&
        this.height && this.minHeight && this.maxHeight &&
        parseInt(this.length) >= parseInt(this.minWidth) &&
        parseInt(this.length) <= parseInt(this.maxWidth) && 
        parseInt(this.height) >= parseInt(this.minHeight) && 
        parseInt(this.height) <= parseInt(this.maxHeight) && 
        parseFloat(this.ratio) > 0;

      return valid;
    },
    getPanelPos(number, amount, fitting) {
      let offset = this.x + this.offset.x + (this.frameWidth * this.ratio);
      let pos = this.getPanelLength();

      if (fitting !== 'left') {
        offset += this.frameWidth * this.ratio * (this.getPanelMultiplier - 1);
        pos = (pos - this.overlapping) * number;
        if (number === amount) { pos += this.getPanelLength() / 1.5; }
      } else {
        pos = (pos - this.overlapping) * number;
        if (number === amount) { pos -= this.getPanelLength() / 1.5; }
      }

      return offset + pos * this.ratio;
    },
    getPanelLength(){
      return (this.length - (this.frameWidth * 2)) / (this.lanes * this.getPanelMultiplier) + (this.overlapping * (this.lanes - 1) / this.lanes);
    },
    getStageHeight() {
      return window.innerWidth <= 460 ? 300 : 
        window.innerWidth <= 768 ? 450 : 580;
    },
    setStageWidth() {
      this.stageSize.width = this.$refs.stage ? this.$refs.stage.$el.clientWidth : 0;
    },
    updateCanvas() {
      const label = { height: 70, width: 60 };
      this.stageSize.height = this.getStageHeight();
      var frameWidth = parseInt(this.length);
      var frameHeight = parseInt(this.height);

      var wr = (this.stageSize.width - label.width) / frameWidth || 0;
      var hr = (this.stageSize.height - label.height) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = Math.abs(ratio);
      this.stageSize.height = this.height * this.ratio + label.height;

      const drawing_width = this.length * this.ratio;
      this.offset.x = (this.stageSize.width - drawing_width) / 2;
    },
    resizeCanvas() {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    }
  }
}
</script>