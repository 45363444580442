<template>
  <validation-observer
    ref="formatObserver"
    v-slot="{ invalid }"
  >
    <el-row 
      v-if="possibleFormats.length"
      style="margin-bottom: 8px;"
    >
      <el-radio-group
        v-model="selectedFormat"
        fill="#1bafd6"
        radius="no"
      >
        <el-radio-button
          v-for="format of possibleFormats"
          :key="format.title"
          :label="format.title"
        >
          <img
            :src="getImgUrl(format)"
            style="width: 24px"
          >
        </el-radio-button>
      </el-radio-group>
    </el-row>
    <el-row
      :gutter="24"
    >
      <el-col
        :xs="12"
        :sm="showWeight ? 8 : 12"
        :md="8"
        :lg="6"
        :xl="4"
      >
        <form-item-wrapper
          :label="$t('width')"
          :required="true"
          :rules="{
            required: true,
            between: { min: minWidth * minLengthMultiplier, max: getMaxWidth * maxLengthMultiplier }
          }"
        >
          <el-input
            v-model.number="length"
            @focus="onFocus"
          >
            <span slot="suffix">mm</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
      <el-col
        :xs="12"
        :sm="showWeight ? 8 : 12"
        :md="8"
        :lg="6"
        :xl="4"
      >
        <form-item-wrapper
          :label="$t('height')"
          :required="true"
          :rules="{
            required: true,
            between: { min: minHeight, max: maxHeight }
          }"
        >
          <el-input
            v-model.number="height"
            @focus="onFocus"
          >
            <span slot="suffix">mm</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
      <el-col
        v-if="showWeight"
        :xs="8"
        :sm="7"
        :md="8"
        :lg="5"
        :xl="3"
      >
        <form-item-wrapper
          :label="$t('weight')"
          :rules="{ required: true }"
        >
          <el-input
            :disabled="true"
            :value="calculateWeight"
          >
            <span slot="suffix">kg</span>
          </el-input>
        </form-item-wrapper>
      </el-col>
    </el-row>

    <GlassThickness 
      :thickness-title="$t('glassThickness')"
      style="margin: 14px 0 8px" 
    />
    <div align="right">
      <el-button
        type="primary"
        :disabled="invalid"
        @click="$emit('nextStep', 1)"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import GlassThickness from "@/components/Glasart/Glasstaerke.vue";

export default {
  name: "Format",
  components: {
    GlassThickness
  },
  props: {
    possibleFormats: {
      type: Array,
      default: () => ([
        { title: "Rechteck", image: "rectangle" },
        { title: "Ellipse", image: "ellipse" },
        { title: "Dreieck", image: "triangle" },
        { title: "Form Schräge Kante", image: "spec_rectangle" },
        { title: "Parallelogramm", image: "parallelogram" },
        { title: "Trapez", image: "trapezium" },
        { title: "Fünfeck", image: "cropped_rectangle" }
      ]),
    },
    minLengthMultiplier: {
      type: Number,
      default: 1,
      description: 
        "Sets a multiplier the current min length will be multiplied with. For example minimal possible sliding wall lanes."
    },
    maxLengthMultiplier: {
      type:Number,
      default: 1,
      description: 
        "Sets a multiplier the current max length will be multiplied with. For example sliding wall lanes and opening directions."
    },
    showWeight: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      "calculateWeight",
      "getMaxWidth",
    ]),
    ...mapFields([
      "selectedFormat",
      "lanes",
      "length",
      "length1",
      "length2",
      "height",
      "height1",
      "minHeight",
      "maxHeight",
      "minWidth"
    ])
  },
  methods: {
    getImgUrl(format) {
      var file = format.image + "_konva";
      var images = require.context("../../assets/", false, /\.svg$/);
      if (this.selectedFormat === format.title) {
        file += "_selected";
      }
      return images("./" + file + ".svg");
    },
    onFocus(evt) {
      evt.target.select();
    }
  }
};
</script>
