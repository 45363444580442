import Vue from "vue";
import VueI18n from 'vue-i18n';

import de from "vee-validate/dist/locale/de.json"
import nl from "vee-validate/dist/locale/nl.json"
import custom from "../../lang/translations/validations"

Vue.use(VueI18n);

function loadLocaleMessages () {
    const locales = require.context('../../lang/translations', false, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)

            // Load the validation messages from vee-validate 
            // library into the messages object.
            messages[locale].validation = Object.assign({}, 
                getVeeValidateLocale(locale).messages, getCustomVeeValidateLocale(locale))
        }
    })
    return messages
}
  
function getVeeValidateLocale (locale) {
    const veeValidateLocales = { de, nl };
    return veeValidateLocales[locale] || veeValidateLocales['de'];
}

function getCustomVeeValidateLocale (locale) {
    return custom[locale] || custom['de'];
}

export default new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: loadLocaleMessages()
});
