<template>
  <v-group>
    <!-- Back wall -->
    <v-rect
      v-if="pos === 'back'"
      :config="{
        x: x,
        y: y,
        width: length,
        height: height,
        fill: fill,
        shadowColor: 'rgba(0, 0, 0, .1)',
        shadowBlur: 5,
        shadowOffset: { x: 0, y: -5 },
      }"
    />

    <!-- Side wall -->
    <v-line
      v-if="pos === 'side'"
      :config="{
        x: x,
        y: y,
        points: [
          0, 0,
          thickness, 0,
          thickness, height,
          0, height,
          0, 0,
          dimensision, Math.abs(dimensision),
          dimensision, height - Math.abs(dimensision),
          0, height,
          0, 0
        ],
        closed: true,
        fill: fill,
        stroke: 'rgba(0, 0, 0, .2)',
        strokeWidth: 1,
      }"
    />
    <Shadow
      v-if="pos === 'side'"
      :x="x"
      :y="y"
      :height="height"
      :dimensision="dimensision"
    />
  </v-group>
</template>
<script>
import Shadow from './Shadow.vue';
export default {
  name: "Wall",
  components: { Shadow },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    pos: {
      type: String,
      default: 'back',
      validator(value) {
        const acceptedValues = ['back', 'side'];
        return acceptedValues.includes(value);
      },
      description: 'Position of the wall (back|side).'
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    dimensision: {
      type: Number,
      default: 0
    },
    fill: {
      type: String,
      default: 'rgb(255, 255, 255)'
    },
    thickness: {
      type: Number,
      default: 15
    }
  }
}
</script>