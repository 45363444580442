<template>
  <div>
    <v-stage
      ref="stage"
      :config="stageSize"
    >
      <v-layer v-if="showStage()">
        <Cabin
          v-if="showStage()"
          :x="x + offset.x"
          :y="y + offset.y"
          :length="length"
          :height="height"
          :wall="wall"
          :ratio="ratio"
          :farbe="farbe"
          :input-length="length"
          :input-height="height"
        />
      
        <!--
          -------------------------------------------------------------------------
          D O O R   S T A R T
        -->
        <Glass
          :x="x + offset.x + ((wall.thickness + hinge.offset + gasket.offset) * ratio)"
          :y="y + offset.y"
          :length="realLength / getDoorCount * ratio"
          :height="realHeight * ratio"
          :input-length="realLength / getDoorCount"
          :input-height="realHeight"
          :active="!activeInput.startsWith('hinge_')"
          :color="glassColor"
          :lines="glassDesign"
          :scale="ratio"
        />
        <Glass
          v-if="getDoorCount > 1"
          :x="x + offset.x + ((wall.thickness + hinge.offset + gasket.offset + 20 + (realLength / getDoorCount)) * ratio)"
          :y="y + offset.y"
          :length="realLength / getDoorCount * ratio"
          :height="realHeight * ratio"
          :input-length="realLength / getDoorCount"
          :input-height="realHeight"
          :active="false"
          :color="glassColor"
          :lines="glassDesign"
          :scale="ratio"
        />

        <Gasket
          v-if="selectedBottomGasket && selectedBottomGasket.title != 'Keine Dichtung'"
          :x="x + offset.x + (wall.thickness * ratio) + (hinge.offset * ratio)"
          :y="y + offset.y"
          :length="(length - hinge.offset) * ratio"
          :height="height * ratio"
          :color="selectedBottomGasket.color"
          :thickness="7 * ratio"
          position="bottom"
        />
        <Gasket
          v-if="selectedSideGasket && selectedSideGasket.title != 'Keine Dichtung'"
          :x="x + offset.x + 1 + (wall.thickness * ratio) + (hinge.offset * ratio)"
          :y="y + offset.y"
          :length="(length - hinge.offset) * ratio - 2"
          :height="height * ratio"
          :color="selectedSideGasket.color"
          :thickness="7 * ratio"
        />
        <Gasket
          v-if="selectedMidGasket"
          :x="x + offset.x + (wall.thickness * ratio) + (length / 2 * ratio)"
          :y="y + offset.y"
          :length="hinge.offset * ratio"
          :height="height * ratio"
          :color="selectedMidGasket.color"
          :thickness="selectedMidGasket.offset * ratio"
        />

        <v-group
          v-for="(h, index) in hingePositions"
          :key="`hinge_${index}`"
        >
          <Hinge
            :x="x + offset.x - 1 + (wall.thickness * ratio) + (fitting === 'left' ? 0 : length * ratio)"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="fitting !== 'left'"
            :scale="ratio"
            :type="hinge.type"
            :material="farbe"
          />
          <Hinge
            v-if="getDoorCount > 1"
            :x="x + offset.x - 1 + (wall.thickness * ratio) + (length * ratio)"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="true"
            :scale="ratio"
            :type="hinge.type"
            :material="farbe"
          />
        </v-group>

        <Button 
          v-for="(drilling, index) in drillings"
          :key="`button_${index}`"
          :x="x + offset.x + ((wall.thickness + hinge.offset + drilling.x + (index > 0 ? length / drillings.length : 0)) * ratio)"
          :y="y + offset.y + ((drilling.y) * ratio)"
          :durchmesser="drilling.durchmesser * ratio"
          :length="x + offset.x + (length * ratio)"
          :real-height="height - drilling.y"
          :active="activeInput == `button_0`"
          :scale="ratio"
          :material="farbe"
        />
        <!--
          -------------------------------------------------------------------------
          D O O R   E N D
        -->
      </v-layer>
    </v-stage>
    <el-alert
      style="margin-top: 12px"
      type="info"
      center
      show-icon
      :closable="false"
    >
      {{ $t('glassDoorDimensision') }} {{ realLength }} x {{ realHeight }} mm
    </el-alert>
  </div>
</template>
<script>
import Cabin from './Cabin.vue';
import Glass from './Parts/Glass.vue';
import Button from './Parts/Button.vue';
import Hinge from './Parts/Hinge.vue';
import Gasket from './Parts/Gasket.vue';
import { mapGetters } from "vuex";
import { mapMultiRowFields, mapFields } from "vuex-map-fields";

export default {
  name: "Shower",
  components: {
    Cabin,
    Glass,
    Button,
    Hinge,
    Gasket
  },
  props: {
    activeInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      timeout: null,
      x: 0,
      y: 0,
      offset: {
        x: 20,
        y: 20
      },
      wall: {
        thickness: 40,
        dimensision: 100
      },
      hinge: {
        type: 'quadrat',
        offset: 2
      },
      gasket: {
        offset: 0
      },
      stageSize: {
        width: 0,
        height: 0,
      },
      ratio: 1,
      weight: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getDoorCount",
      "getMaxWidth"
    ]),
    ...mapMultiRowFields(["drillings"]),
    ...mapFields([
      "farbe",
      "fitting",
      "glassColor",
      "glassDesign",
      "minWidth",
      "minHeight",
      "maxHeight",
      "length",
      "height",
      "selectedHinge",
      "hingePositions",
      "selectedBottomGasket",
      "selectedMidGasket",
      "selectedSideGasket"
    ]),
    realLength() {
      return this.$store.getters.calculateShowerGlassWidth;
    },
    realHeight() {
      return this.$store.getters.calculateShowerGlassHeight;
    }
  },
  watch: {
    length() {
      this.lazyCaller(this.updateCanvas());
    },
    height() {
      this.lazyCaller(this.updateCanvas());
    },
    selectedHinge() {
      this.hinge.offset = this.selectedHinge?.offset || 2;
      this.hinge.type = this.selectedHinge?.format || 'quadrat';
    },
    selectedSideGasket() {
      this.gasket.offset = (this.selectedSideGasket?.offset || 0);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    });
  },
  created() {
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    lazyCaller(callback, time = 150) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(callback, time)
    },
    showStage() {
      const valid = this.length && this.minWidth && this.getMaxWidth &&
        this.height && this.minHeight && this.maxHeight &&
        parseInt(this.length) >= parseInt(this.minWidth) &&
        parseInt(this.length) <= parseInt(this.getMaxWidth) && 
        parseInt(this.height) >= parseInt(this.minHeight) && 
        parseInt(this.height) <= parseInt(this.maxHeight) && 
        parseFloat(this.ratio) > 0;

      return valid;
    },
    getStageHeight() {
      return window.innerWidth <= 460 ? 300 : 
        window.innerWidth <= 768 ? 450 : 580;
    },
    setStageWidth() {
      this.stageSize.width = this.$refs.stage ? this.$refs.stage.$el.clientWidth : 0;
    },
    updateCanvas() {
      const label = { height: 70, width: 120 };
      this.stageSize.height = this.getStageHeight();
      var frameWidth = parseInt(this.length);
      var frameHeight = parseInt(this.height);

      var wr = (this.stageSize.width - label.width) / frameWidth || 0;
      var hr = (this.stageSize.height - label.height) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = Math.abs(ratio);
      this.stageSize.height = this.height * this.ratio + label.height;

      const drawing_width = (this.length + (this.wall.thickness * 2)) * this.ratio;
      this.offset.x = (this.stageSize.width - drawing_width) / 2;
    },
    resizeCanvas() {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    }
  }
}
</script>
