<template>
  <v-rect
    :config="{
      x: x,
      y: y - (23.5 + height / 2),
      width: length,
      height: height,
      fill: color
    }"
  />
</template>
<script>
export default {
  name: "ButtonTopDown",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "#222"
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
  }
}
</script>