<template>
  <!-- Button -->
  <v-circle
    :config="{
      x: x,
      y: y,
      radius: radius,
      fill: color,
      shadowColor: 'rgba(0, 0, 0, .1)',
      shadowBlur: 2,
      shadowOffset: { x: -2, y: 0 },
    }"
  />
</template>
<script>
export default {
  name: "Button",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#222'
    },
    radius: {
      type: Number,
      default: 0
    },
  },
}
</script>