var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-rect',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y - 25,
    width: _vm.length,
    height: _vm.height,
    fill: _vm.darkerColor
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }