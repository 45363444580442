var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-circle',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    radius: _vm.radius,
    fill: _vm.color,
    shadowColor: 'rgba(0, 0, 0, .1)',
    shadowBlur: 2,
    shadowOffset: { x: -2, y: 0 },
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }