var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"uploadObserver",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',[_vm._l((_vm.drillings),function(drilling,index){return _c('el-row',{key:index,attrs:{"gutter":12}},[_c('el-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('drillingsTitle'))+" "),_c('i',{staticClass:"el-icon-delete delete-icon",on:{"click":function($event){return _vm.removeDrilling(index)}}})])]),_c('el-col',{attrs:{"span":8}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('diameter'),"rules":{
            required: true,
            numeric: true,
            inside_values_or_gt: { diameters: _vm.getDiameters, gt: _vm.diameter_gt }
          },"use-slot":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"el-icon-edit"}),_c('span',{staticClass:"drilling_text"},[_vm._v(_vm._s(_vm.$t('diameter')))])]),_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('div',{staticClass:"extended-info"},[_vm._v(" "+_vm._s(_vm.$t('diameterInfo'))+" "),_c('el-table',{attrs:{"data":_vm.getTableDiameter,"height":"200"}},[_c('el-table-column',{attrs:{"property":"diameter","label":_vm.$t('diameter')}})],1)],1),_c('i',{staticClass:"el-icon-info info-icon",attrs:{"slot":"reference"},slot:"reference"})]),_c('el-select',{attrs:{"filterable":"","allow-create":"","clearable":"","placeholder":_vm.$t('diameterPlaceholder')},model:{value:(drilling.durchmesser),callback:function ($$v) {_vm.$set(drilling, "durchmesser", $$v)},expression:"drilling.durchmesser"}},_vm._l((_vm.getDiameters),function(diameter){return _c('el-option',{key:diameter,attrs:{"label":diameter,"value":diameter}})}),1)],1)],1),_c('el-col',{attrs:{"span":8}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('distanceLeft'),"rules":{
            required: true,
            numeric: true,
            point_in_form: { form: _vm.getFormCoordinates(drilling.durchmesser), x: drilling.x, y: _vm.height - drilling.y }
          },"use-slot":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"el-icon-right"}),_c('span',{staticClass:"drilling_text"},[_vm._v(_vm._s(_vm.$t('distanceLeft')))])]),_c('el-input',{model:{value:(drilling.x),callback:function ($$v) {_vm.$set(drilling, "x", $$v)},expression:"drilling.x"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1),_c('el-col',{attrs:{"span":8}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('distanceTop'),"rules":{
            required: true,
            numeric: true,
            point_in_form: { form: _vm.getFormCoordinates(drilling.durchmesser), x: drilling.x, y: _vm.height - drilling.y }
          },"use-slot":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"el-icon-bottom"}),_c('span',{staticClass:"drilling_text"},[_vm._v(_vm._s(_vm.$t('distanceTop')))])]),_c('el-input',{model:{value:(drilling.y),callback:function ($$v) {_vm.$set(drilling, "y", $$v)},expression:"drilling.y"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1)],1)}),(_vm.drillings.length === 0 && _vm.uploadData.length === 0)?_c('el-row',{attrs:{"gutter":12}},[_c('el-col',[_c('el-button',{attrs:{"type":"primary","disabled":invalid,"size":"small"},on:{"click":function($event){return _vm.$emit('nextStep', 1)}}},[_vm._v(" "+_vm._s(_vm.$t('drillingSkipBtn'))+" ")])],1)],1):_vm._e(),(_vm.drillings.length === 0 && _vm.uploadData.length === 0)?_c('el-row',{attrs:{"gutter":12}},[_c('el-col',[_c('p',[_vm._v(_vm._s(_vm.$t('or')))])])],1):_vm._e(),_c('el-row',{attrs:{"gutter":12}},[_c('el-col',[_c('el-button',{staticClass:"mt-0",attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addDrilling()}}},[_vm._v(" "+_vm._s(_vm.$t('drillingAddBtn'))+" ")])],1)],1),_c('el-row',{attrs:{"gutter":12}},[_c('el-col',[_c('p',[_vm._v(_vm._s(_vm.$t('or')))])])],1),_c('el-row',{attrs:{"gutter":12}},[_c('el-col',{staticClass:"col-upload-sketch"},[_c('el-upload',{ref:"upload",staticClass:"upload-sketch",attrs:{"accept":"application/pdf","action":"","before-upload":_vm.beforeSketchUpload,"limit":1,"on-remove":_vm.handleRemove,"file-list":_vm.uploadData,"http-request":_vm.upload}},[_c('el-button',{attrs:{"disabled":_vm.uploadData.length == 1,"icon":"el-icon-document-add","size":"small","type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('uploadSketch'))+" ")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" "+_vm._s(_vm.$t('uploadPdfCondition'))+" ")])],1)],1),(_vm.uploadData.length == 1)?_c('el-col',{attrs:{"md":12}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('quantity'),"rules":{
            numeric: true,
            between: {
              min: 1,
              max: 15
            }
          },"use-slot":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.$t('drillingsSketchMsg')))])]),_c('el-input-number',{attrs:{"size":"small","min":0,"max":15},model:{value:(_vm.drillingsCounter),callback:function ($$v) {_vm.drillingsCounter=$$v},expression:"drillingsCounter"}})],1)],1):_vm._e()],1),_c('div',{attrs:{"align":"right"}},[_c('el-button',{attrs:{"type":"primary","disabled":invalid},on:{"click":function($event){return _vm.$emit('nextStep', 1)}}},[_vm._v(" "+_vm._s(_vm.$t('nextBtn'))+" ")])],1)],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }