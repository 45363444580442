<template>
  <validation-observer
    ref="cornerObserver"
    v-slot="{ invalid }"
  >
    <div>
      <el-row
        v-for="corner in getCorners"
        :key="corner"
      >
        <el-col>
          <el-card shadow="never">
            <div
              :class="corners[corner].name === $t('cornerPointed') ? 'isSelected' : ''"
              class="small-box"
              @click="corners[corner].name = $t('cornerPointed')"
            >
              <img
                :src="
                  getImgUrl('corner_standard', corners[corner].name === $t('cornerPointed'))
                "
                alt=""
                :style="getRotationForEdge(corner)"
              >
            </div>
            <div
              :class="corners[corner].name === $t('cornerBevelled') ? 'isSelected' : ''"
              class="small-box"
              @click="corners[corner].name = $t('cornerBevelled')"
            >
              <img
                :src="
                  getImgUrl(
                    'corner_pushed',
                    corners[corner].name === $t('cornerBevelled')
                  )
                "
                alt=""
                :style="getRotationForEdge(corner)"
              >
            </div>
            <div
              :class="corners[corner].name === $t('cornerRounded') ? 'isSelected' : ''"
              class="small-box"
              @click="corners[corner].name = $t('cornerRounded')"
            >
              <img
                :src="
                  getImgUrl('corner_rounded', corners[corner].name === $t('cornerRounded'))
                "
                alt=""
                :style="getRotationForEdge(corner)"
              >
            </div>

            <el-row
              v-if="corners[corner].name != $t('cornerPointed')"
              style="margin-top: 10px; margin-bottom: -10"
              :gutter="12"
            >
              <el-col
                v-if="corners[corner].name === $t('cornerBevelled')"
                :span="12"
              >
                <form-item-wrapper
                  label="X"
                  :rules="{
                    required: true,
                    numeric: true,
                    min_value: 1
                  }"
                >
                  <el-input v-model="corners[corner].x">
                    <span slot="suffix">mm</span>
                  </el-input>
                </form-item-wrapper>
              </el-col>
              <el-col
                v-if="corners[corner].name === $t('cornerBevelled')"
                :span="12"
              >
                <form-item-wrapper
                  label="Y"
                  :rules="{
                    required: true,
                    numeric: true,
                    min_value: 1
                  }"
                >
                  <el-input v-model="corners[corner].y">
                    <span slot="suffix">mm</span>
                  </el-input>
                </form-item-wrapper>
              </el-col>
              <el-col
                v-else
                :span="12"
              >
                <form-item-wrapper
                  label="r"
                  :required="true"
                  :rules="{
                    required: true,
                    numeric: true,
                    min_value: 1
                  }"
                >
                  <el-input v-model="corners[corner].r">
                    <span slot="suffix">mm</span>
                  </el-input>
                </form-item-wrapper>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <div align="right">
        <el-button
          type="primary"
          :disabled="invalid"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";
export default {
  name: "Ecken",
  data() {
    return {};
  },
  computed: {
    ...mapFields(["selectedFormat", "corners"]),
    getCorners() {
      var selectedFormat = this.selectedFormat;
      var corners = Object.keys(this.corners);
      if (selectedFormat === i18n.t('formTriangle')) {
        corners = corners.filter(corner => corner != "oben_rechts");
      }
      if (selectedFormat !== i18n.t('formPentagon')) {
        corners = corners.filter(corner => corner != "oben_mitte");
      }
      return corners;
    }
  },
  methods: {
    next() {
      this.$emit("nextStep", 3);
    },
    getRotationForEdge(corner) {
      let styleString = "";
      switch (corner) {
        case "oben_links":
          styleString = "transform: rotate(-90deg)";
          break;
        case "unten_rechts":
          styleString = "transform: rotate(90deg)";
          break;
        case "unten_links":
          styleString = "transform: rotate(180deg)";
          break;
        default:
          styleString = "transform: rotate(0deg)";
      }
      return styleString;
    },
    getImgUrl(filename, isSelected) {
      var images = require.context("../../assets/", false, /\.svg$/);
      if (isSelected) {
        filename += "_selected";
      }
      return images("./" + filename + ".svg");
    }
  }
};
</script>

<style lang="css" scoped>

.small-box {
    display: inline-flex;
    color: white;
    margin-right: 4px;
    margin-bottom: -4px;
    border: 1px solid #0000004d;
}

/deep/ .el-form-item {
    margin-bottom: 0
}
</style>
