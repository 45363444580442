<template>
  <div>
    <!-- Button -->
    <image-component
      :x="x"
      :y="y"
      :file="getFilePath()"
      align="center"
      vertical="center"
      :scale="scale"
    />
    <v-line
      v-if="active"
      :config="{
        x: x,
        y: y,
        points: [
          -10, 0,
          10, 0,
          0, 0,
          0, -10,
          0, 10,
        ],
        closed: false,
        stroke: 'rgba(200, 0, 0, 1)',
        strokeWidth: 1,
      }"
    />

    <v-line
      v-if="active"
      :config="{
        x: length + 15,
        y: y,
        points: [
          15, 0,
          20, 0,
          20, realHeight * scale,
          15, realHeight * scale
        ],
        closed: false,
        stroke: 'rgba(200, 0, 0, 1)',
        strokeWidth: 1,
      }"
    />
    <v-label
      v-if="active"
      :config="{
        x: length + 30,
        y: y + (realHeight * scale / 2) + 25
      }"
    >
      <v-tag :config="{ fill: '#fff', rotation: 270 }" />
      <v-text
        :config="{
          text: `${realHeight} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          rotation: 270,
          width: 50
        }"
      />
    </v-label>
  </div>
</template>
<script>
import ImageComponent from './ImageComponent.vue';

export default {
  name: "Button",
  components: { ImageComponent },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    realHeight: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getFilePath() {
      return `./button/${this.material.toLowerCase()}.svg`;
    }
  }
}
</script>